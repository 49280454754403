import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ShowCurrencies from '../../utils/showCurrencies';
import Skeleton from 'react-loading-skeleton';
import CheckBox from './checkbox';
import CustomButton from './button';
import useHandleAddProduct from '../../utils/handleAddProduct';
import SelectCurrency from './selectCurrency';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../store/user';
import audCowBundleImg from '../../images/landings/qurban/cow-aud.png';
import aedCowBundleImg from '../../images/landings/qurban/cow-aed.png';
import eurCowBundleImg from '../../images/landings/qurban/cow-eur.png';
import usdCowBundleImg from '../../images/landings/qurban/cow-usd.png';
import cadCowBundleImg from '../../images/landings/qurban/cow-cad.png';
import myrCowBundleImg from '../../images/landings/qurban/cow-myr.png';
import sgdCowBundleImg from '../../images/landings/qurban/cow-sgd.png';
import gbpCowBundleImg from '../../images/landings/qurban/cow-gbp.png';
import audGoatBundleImg from '../../images/landings/qurban/goat-aud.png';
import aedGoatBundleImg from '../../images/landings/qurban/goat-aed.png';
import eurGoatBundleImg from '../../images/landings/qurban/goat-eur.png';
import usdGoatBundleImg from '../../images/landings/qurban/goat-usd.png';
import cadGoatBundleImg from '../../images/landings/qurban/goat-cad.png';
import myrGoatBundleImg from '../../images/landings/qurban/goat-myr.png';
import sgdGoatBundleImg from '../../images/landings/qurban/goat-sgd.png';
import gbpGoatBundleImg from '../../images/landings/qurban/goat-gbp.png';
import { generateLinkWithLang } from '../../utils/linkGenerator';

const MultiSelectProductsBox = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const { products, checkboxProducts, bundleProduct, currency, title, country, isOneLine, hideOuterBundleImage = false, className, onSelectCurrencyOpen, onSelectCurrencyClose, _lang = 'en' } = props;
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const navigate = useNavigate();
    const [addProduct] = useHandleAddProduct();
    const [topProducts, setTopProducts] = useState([0, 0, 0, 0, 0, 0]);
    const [bottomProducts, setBottomProducts] = useState([0, 0, 0]);
    const [isBundleProductAdded, setIsBundleProductAdded] = useState(false)
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [isZakat, setIsZakat] = useState(false);
    const [currentCurrency, setCurrentCurrency] = useState(currency);
    const cowBunldeImages = {
        usd: usdCowBundleImg,
        eur: eurCowBundleImg,
        aud: audCowBundleImg,
        sgd: sgdCowBundleImg,
        myr: myrCowBundleImg,
        gbp: gbpCowBundleImg,
        aed: aedCowBundleImg,
        cad: cadCowBundleImg
    }
    const goatBunldeImages = {
        usd: usdGoatBundleImg,
        eur: eurGoatBundleImg,
        aud: audGoatBundleImg,
        sgd: sgdGoatBundleImg,
        myr: myrGoatBundleImg,
        gbp: gbpGoatBundleImg,
        aed: aedGoatBundleImg,
        cad: cadGoatBundleImg
    }
    const { lang } = useParams(); // Get the language from the URL
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const handleCurrency = (currency, product) => {
        if (product.quantity) {
            return ShowCurrencies(currency, product[currency.toLowerCase()] * product.quantity, true);
        } else {
            return ShowCurrencies(currency, product[currency.toLowerCase()], true);
        }
    }
    const handleChangeCurrency = (currency) => {
        let tempTotalAmount = 0;
        let tempSelectedPrd = selectedProducts;
        tempSelectedPrd.map(item => tempTotalAmount += item[currency.toLowerCase()]);
        setTotalAmount(tempTotalAmount)
    }
    const handleSelectProducts = (prd) => {
        let tempTotalAmount = 0;
        let existedPrdIndex = -1;
        let tempSelectedPrd = selectedProducts;
        if (isZakat) {
            tempSelectedPrd = [prd]
        } else {
            selectedProducts.length && selectedProducts.map((item, index) => {
                if (item.creator === prd.creator) existedPrdIndex = index;
                return item;
            })
            existedPrdIndex !== -1
                ? tempSelectedPrd.splice(existedPrdIndex, 1)
                : tempSelectedPrd.push(prd)
            console.log("tempSelectedPrd=", tempSelectedPrd, prd)
            // tempSelectedPrd.map(item => tempTotalAmount += item[currentCurrency ? currentCurrency.toLowerCase() : 'AUD'])
            tempSelectedPrd.map(item => {
                if (item) {
                    tempTotalAmount += item[currentCurrency ? currentCurrency.toLowerCase() : 'AUD']
                }
                return item;
            })
        }
        setTotalAmount(tempTotalAmount)
        setSelectedProducts(tempSelectedPrd)
    }
    const checkSelectedProduct = (prd) => {
        let isExist = false;
        selectedProducts.map(item => {
            if (item.creator === prd.creator) isExist = true
            return item;
        })
        return isExist;
    }
    const handleDonate = () => {
        if (isZakat) {
            if (selectedProducts[0].staticLink) {
                navigate(generateLinkWithLang(selectedProducts[0].staticLink, lang))
            } else {
                addProduct({product: selectedProducts[0], currency: currentCurrency})
            }
        } else {
            addProduct({product: selectedProducts, currency: currentCurrency, type: 'multiple'})
        }
    }
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        if (products.length) {
            // console.log("on useeffect=", products, products[0], products[1])
            if (country) {
                const selectedProduct = products.find(item => item.name.toLowerCase() === country.toLowerCase())
                handleSelectProducts(selectedProduct ?? products[0] ?? products[1])
            } else {
                products[0] ? handleSelectProducts(products[0]) : handleSelectProducts(products[1])
            }
            setTopProducts(products)
        }
        checkboxProducts && checkboxProducts.length ? setBottomProducts(checkboxProducts) : setBottomProducts([])
        // console.log("bundle=", products, checkboxProducts, bundleProduct)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products, checkboxProducts]);
    useEffect(() => {
        (title.toLowerCase() === 'zakat' || title.toLowerCase() === 'زكاة') ? setIsZakat(true) : setIsZakat(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title]);
    useEffect(() => {
        selectedCurrencyStore ? setCurrentCurrency(selectedCurrencyStore) : setCurrentCurrency('AUD');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCurrencyStore]);
    /* -------------------------------------------------------------------------- */
    return (
        <div className="w-full flex flex-col">
            {bundleProduct && bundleProduct.product && !hideOuterBundleImage && <div className="w-full bg-[#14A2DC] rounded-md flex justify-between items-center p-1 md:py-2 md:px-4 mb-4 md:mb-8">
                {/* <img src={bundleProduct.image} alt={bundleProduct.title} className='max-w-[45%] md:max-w-[280px]' /> */}
                <img src={bundleProduct.type === 'cow' ? cowBunldeImages[currentCurrency ? currentCurrency.toLowerCase() : 'aud'] : goatBunldeImages[currentCurrency ? currentCurrency.toLowerCase() : 'aud']} alt={bundleProduct.title} className='max-w-[45%] md:max-w-[280px]' />
                <h2 className="text-white sm:text-lg md:text-[40px] text-center max-[600px]:max-w-[20%]-">{bundleProduct.title}</h2>
                {/* {console.log("bundle=", bundleProduct)} */}
                <CustomButton
                    onClick={() => {
                        console.log("clicked=", isBundleProductAdded, bundleProduct.product)
                        if (!isBundleProductAdded && bundleProduct.product.id) {
                            handleSelectProducts(bundleProduct.product)
                            setIsBundleProductAdded(true)
                        }
                    }}
                    className={`max-[600px]:text-xs !px-1 sm:!px-2 md:!px-12 hover:!bg-[#b70048] ${_lang === 'ar' ? 'font-[AlmariMedium] font-bold' : ''}`}
                    title={_lang === 'fr' ? 'Ajouter' : _lang === 'ar' ? 'اضافة' : 'Add'}
                />
            </div>}
            <div className={`w-full md:w-1/2 lg:w-1/3 p-2 md:p-3 rounded-xl shadow-lg bg-white mx-auto ${className || ''}`}>
                <h3 className={`text-[#253B7E] flex justify-center items-center text-2xl text-center font-medium mt-2 uppercase ${_lang === 'ar' ? 'font-[AlmariMedium] font-bold' : ''}`}>
                    {title ? title : <Skeleton height={56} />}
                </h3>
                {bundleProduct && bundleProduct.product && hideOuterBundleImage && <div className="w-full bg-[#CDF1FF] rounded-md flex justify-between items-center p-1 md:py-2 md:px-4 my-2 md:my-4">
                    <h2 className="text-[#253B7E] text-xs sm:text-sm font-bold max-w-[80px]">
                        {/* {bundleProduct.type === 'cow' ? `Ultimate Qurban ${<span className='italic font-light'>Plus</span>}` : 'Ultimate Qurban'} */}
                        Ultimate Qurban {bundleProduct.type === 'cow' && <span className='italic font-light'>Plus</span>}
                        {/* {bundleProduct.type === 'cow' ? `}` : 'Ultimate Qurban'} */}
                    </h2>
                    <div className="flex items-center justify-center">
                        <img src={bundleProduct.image} alt="qurban ultimate" className='max-w-[70%] md:max-w-[60%]' />
                        <span className='text-black text-sm md:text-lg font-bold min-w-[50px]'>
                            {ShowCurrencies(selectedCurrencyStore, bundleProduct.product[selectedCurrencyStore.toLowerCase()], true)}
                            {/* {isLoaded
                                ? ShowCurrencies(selectedCurrencyStore, bundleProduct.product[selectedCurrencyStore.toLowerCase()], true)
                                : <Skeleton height={38} />
                            } */}
                        </span>
                    </div>
                    {!isBundleProductAdded && bundleProduct.product.id && <CustomButton
                        onClick={() => {
                            if (!isBundleProductAdded && bundleProduct.product.id) {
                                handleSelectProducts(bundleProduct.product)
                                setIsBundleProductAdded(true)
                            }
                        }}
                        className='max-[600px]:text-xs !px-1 sm:!px-2 md:!px-12- hover:!bg-[#b70048]'
                        title={'Add'} />}
                </div>}
                <p className={`text-[#797979] text-sm text-center mt-2 mb-6 ${_lang === 'ar' ? 'font-[AlmariMedium] font-bold' : ''}`}>
                    {_lang === 'fr' ? 'Vous pouvez sélectionner plusieurs produits à donner' : _lang === 'ar' ? 'يمكنك اختيار منتجات متعددة للتبرع بها' : 'You can select multiple products to donate'}
                </p>
                <div className="grid grid-cols-2 gap-1 md:gap-x-2">
                    {console.log("top products=", topProducts)}
                    {topProducts.map((item, index) => {
                        return <div key={`products${index}`} className={(isZakat || isOneLine) ? 'col-span-2' : 'col-span-1'}>
                            {item && item.name
                                ? <div
                                    className={`flex flex-wrap h-full justify-between items-center ${!item.isSoldOut ? 'cursor-pointer' : 'cursor-not-allowed'} ${item.nameDescription ? 'pt-2 pb-1' : 'py-2'} px-1 text-xs sm:text-sm rounded-md ${_lang === 'ar' ? 'font-[AlmariMedium] font-bold' : ''} ${checkSelectedProduct(item) ? 'bg-[#f60362] text-white' : 'text-[#253B7E] shadow-md'}`}
                                    onClick={() => !item.isSoldOut && handleSelectProducts(item)}>
                                    {isZakat
                                        ? <div className={`basis-full whitespace-nowrap overflow-hidden text-center`}>
                                            {item.alternativeName || item.name}
                                        </div>
                                        : <>
                                            <div className={`sm:basis-7/12- flex flex-col whitespace-nowrap overflow-hidden ${item.isSoldOut ? 'text-[#C3C3C3]' : ''}`}>
                                                <div className="flex whitespace-nowrap">
                                                    {item.showHeartIcon && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`w-4 mr-0.5 ${checkSelectedProduct(item) ? 'text-white' : 'text-[#f60362]'}`}>
                                                        <path d="m11.645 20.91-.007-.003-.022-.012a15.247 15.247 0 0 1-.383-.218 25.18 25.18 0 0 1-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0 1 12 5.052 5.5 5.5 0 0 1 16.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 0 1-4.244 3.17 15.247 15.247 0 0 1-.383.219l-.022.012-.007.004-.003.001a.752.752 0 0 1-.704 0l-.003-.001Z" />
                                                    </svg>
                                                    }
                                                    {item.alternativeName || item.name}
                                                </div>
                                                {item.nameDescription && <div className={`basis-full whitespace-nowrap overflow-hidden text-[10px]`}>
                                                    {item.nameDescription}
                                                </div>}
                                            </div>
                                            <div className={`sm:basis-5/12- flex-1 text-right font-bold ${item.isSoldOut ? 'text-[#f60362] -rotate-6 mt-1 pr-1' : ''}`}>
                                                {item.isSoldOut ? _lang === 'fr' ? 'épuisé' : _lang === 'ar' ? 'نفذ' : 'Sold Out' : handleCurrency(currentCurrency, item)}
                                                {/* {handleCurrency(currentCurrency, item)} */}
                                            </div>
                                        </>
                                    }
                                    {/* {item.nameDescription && <div className={`basis-full whitespace-nowrap overflow-hidden text-[10px]`}>
                                        {item.nameDescription}
                                    </div>} */}
                                </div> : <Skeleton height={38} />}
                        </div>
                    })}
                </div>
                {!isZakat && <div className={`grid grid-cols-3 gap-1 md:gap-x-2 mt-3 ${_lang === 'ar' ? 'font-[AlmariMedium] font-bold' : ''}`}>
                    <div className={`col-span-2 py-2`}>
                        {isBundleProductAdded && bundleProduct.product && <div className="w-full flex items-center [&>input]:mt-0 mb-1">
                            <CheckBox
                                name={`bundleProduct`}
                                value={checkSelectedProduct(bundleProduct.product)}
                                checked={checkSelectedProduct(bundleProduct.product)}
                                setter={() => handleSelectProducts(bundleProduct.product)}
                            >
                                <div className="w-full flex flex-wrap text-xs text-[#78716C]">
                                    {bundleProduct.product.name}
                                    <span className="pl-1 font-bold text-[#f60362]">
                                        {handleCurrency(currentCurrency, bundleProduct.product)}
                                    </span>
                                </div>
                            </CheckBox>
                        </div>}
                        {bottomProducts.length > 0 && bottomProducts.map((item, index) => {
                            return item && <div key={`checkboxProducts${index}`} className="w-full flex items-center [&>input]:mt-0 mb-1">
                                {item.name
                                    ? <CheckBox
                                        name={`bottomProduct${index}`}
                                        value={checkSelectedProduct(item)}
                                        checked={checkSelectedProduct(item)}
                                        setter={() => handleSelectProducts(item)}
                                    >
                                        <div className="w-full flex flex-wrap text-xs text-[#78716C]">
                                            {item.name}
                                            <span className="pl-1 font-bold text-[#f60362]">
                                                {handleCurrency(currentCurrency, item)}
                                            </span>
                                        </div>
                                    </CheckBox>
                                    : <Skeleton height={20} />
                                }
                            </div>
                        })}
                    </div>
                    <div className={`col-span-1 pl-1 py-2`}>
                        <div className="flex flex-col justify-center items-center bg-[#E1EFF2] rounded-lg p-2 h-full">
                            <span className="text-xs text-[#253B7E]">
                                {_lang === 'fr' ? 'Montant total' : _lang === 'ar' ? 'المبلغ الإجمالي' : 'Total Amount'}
                            </span>
                            <span className="text-lg text-[#F60362] font-bold">
                                {ShowCurrencies(currentCurrency, totalAmount, true)}
                            </span>
                        </div>
                    </div>
                </div>}
                <div className={`flex gap-1 md:gap-x-2 mt-3 ${bundleProduct && hideOuterBundleImage ? 'fixed- md:relative w-[calc(100%_-_16px)]-' : ''}`}>
                    <SelectCurrency
                        onChangeCurrency={e => {
                            handleChangeCurrency(e.value)
                            setCurrentCurrency(e.value)
                        }}
                        classNamePrefix="orphan_select"
                        className="!w-[85px]- !text-sm border border-stone-300 rounded-md h-[50px] !max-h-[50px]"
                        onMenuOpen={onSelectCurrencyOpen}
                        onMenuClose={onSelectCurrencyClose}
                    />
                    <CustomButton
                        disabled={!selectedProducts.length}
                        onClick={() => handleDonate()}
                        className={`min-w-[140px] !h-[50px] ${_lang === 'ar' ? 'font-[AlmariMedium] font-bold' : ''}`}
                        title={_lang === 'fr' ? 'Faites un don maintenant' : _lang === 'ar' ? 'تبرع الآن' : 'Donate Now'} />
                </div>
            </div>
        </div>
    );
}

export default MultiSelectProductsBox;