import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectSelectedCurrency } from "../../store/user";
import SelectCurrency from "../../components/general/selectCurrency";
import ReactSelect from "react-select";
import useHandleAddProduct from "../../utils/handleAddProduct";
import ShowCurrencies from "../../utils/showCurrencies";
// import ConvertAUD from "../../utils/convertAUD";

const styles = {
    control: (base) => ({
        ...base,
        height: '48px',
        border: 'none',
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.05)'
    }),
    menuList: (base) => ({
        ...base,
        "::-webkit-scrollbar": {
            width: "6px",
            height: "0px",
        },
        "::-webkit-scrollbar-track": {
            background: "#f1f1f1"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#888"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    }),
    shadow: {
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)'
    }
}

const DetailsWidget = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const { defaultProduct, isLoaded, isAnyAmount } = props;
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [productAmount, setProductAmount] = useState('');
    const [currentCurrency, setCurrentCurrency] = useState();
    const [addProduct] = useHandleAddProduct();
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const suggestedRecurringModes = [
        { value: 'one-off', label: 'Single' },
        { value: 'week', label: 'Weekly' },
        { value: 'month', label: 'Monthly' },
        { value: 'year', label: 'Yearly' },
    ]
    const [selectedRecurring, setSelectedRecurring] = useState(suggestedRecurringModes[0]);
    const handleAddNewProduct = () => {
        let tempProduct = defaultProduct;
        // let customAmount = Number(productAmount) ? Number(productAmount) : Number(selectedPrice.value) ? Number(selectedPrice.value) : 100;
        let customAmount = Number(productAmount) ? Number(productAmount) : 100;
        tempProduct = Object.assign({}, tempProduct, {
            quantity: isAnyAmount ? customAmount : 1,
            recurring: selectedRecurring.value
        })
        addProduct({product: tempProduct, currency: currentCurrency})
    }
    // const handleSelectedItem = (currency, index) => {
    //     let tempSelectedPrice = {};
    //     tempSelectedPrice.index = index;
    //     tempSelectedPrice.value = suggestedPrices[index][currency.toLowerCase()];
    //     setSelectedPrice(tempSelectedPrice)
    // }
    // const handleConvert = (currency, value) => {
    //     return Math.round(ConvertAUD(currency, value, false))
    // }
    // const handleProductAmount = (currency, value) => {
    //     setProductAmount(handleConvert(currency, value))
    // }
    const handleCurrency = (currency) => {
        let productAmount = 1;
        currency && defaultProduct.price_points.map(item => {
            if (item.currency.toLowerCase() === currency.toLowerCase()) {
                productAmount = item.amount;
            }
            return item
        })
        return ShowCurrencies(currency, productAmount, false);
    }
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        selectedCurrencyStore ? setCurrentCurrency(selectedCurrencyStore) : setCurrentCurrency('AUD');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCurrencyStore])
    /* -------------------------------------------------------------------------- */
    return (
        <div className="w-full bg-[#E2EFF3] md:bg-transparent rounded-lg md:rounded-none overflow-hidden-">
            <div className="basis-full grid grid-cols-12 gap-2 p-4 md:p-0">
                <div className="hidden- flex md:flex order-2 md:order-1 col-span-12 sm:col-span-6 lg:col-span-3 lg:mb-2- items-center justify-center" style={styles.shadow}>
                    <SelectCurrency onChangeCurrency={(e) => setCurrentCurrency(e.value)} classNamePrefix="orphan_select" />
                </div>
                <div className="hidden- md:flex md:order-2 col-span-12 sm:col-span-6 lg:col-span-3 lg:mb-2- items-center justify-center rounded-md" style={styles.shadow}>
                    <ReactSelect
                        options={suggestedRecurringModes}
                        className='[&>*]:!cursor-pointer w-full'
                        value={selectedRecurring}
                        isSearchable={false}
                        styles={styles}
                        onChange={(e) => {
                            setSelectedRecurring(e);
                        }}
                    />
                </div>
                <div className="hidden- flex md:flex order-3 col-span-12 sm:col-span-6 lg:col-span-3 lg:mb-2- items-center justify-center rounded-md" style={styles.shadow}>
                    {isAnyAmount
                        ? <input
                            value={productAmount}
                            className="w-full md:w-1/2- h-12 p-2 rounded-md text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent"
                            type="number"
                            onChange={(e) => {
                                setProductAmount(e.target.value);
                            }}
                            placeholder="Enter amount" />
                        : <div className="text-[#f60362] col-span-6 text-md md:text-xl text-center font-bold h-6 md:h-7 flex items-center">
                            {handleCurrency(currentCurrency)}
                            <small className={`uppercase ml-1`}>{currentCurrency}</small>
                        </div>
                    }
                </div>
                {/* {isAnyAmount
                    ? <div className="order-3 flex md:hidden col-span-12 items-center justify-center" style={styles.shadow}>
                        <div className="flex w-full">
                            <SelectCurrency onChangeCurrency={(e) => setCurrentCurrency(e.value)} classNamePrefix="orphan_select" />
                        </div>
                        <input
                            value={productAmount}
                            className="w-full h-12 p-2 rounded-md text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent"
                            type="number"
                            onChange={(e) => {
                                setProductAmount(e.target.value);
                            }}
                            placeholder="Enter amount" />
                    </div>
                    : <div className="order-3 flex flex-wrap md:hidden col-span-12 items-center justify-center rounded-md overflow-hidden" style={styles.shadow}>
                        <div className="flex basis-full sm:basis-1/2">
                            <SelectCurrency onChangeCurrency={(e) => setCurrentCurrency(e.value)} classNamePrefix="orphan_select" />
                        </div>
                        <div className="basis-full sm:basis-1/2 bg-white mt-2 sm:mt-0 h-12 flex justify-center items-center">
                            <h2 className="text-[#f60362] col-span-6 text-xl text-center font-bold h-6">
                                {handleCurrency(currentCurrency)}
                                <small className={`uppercase ml-1`}>{currentCurrency}</small>
                            </h2>
                        </div>
                    </div>
                } */}
                <div className="order-4 col-span-12 sm:col-span-6 lg:col-span-3 lg:mb-2- flex flex-col justify-center items-center-">
                    <button
                        className={`text-white w-full text-lg md:text-lg transition-all duration-200 flex items-center justify-center h-12 px-3 rounded-md ${isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'}`}
                        onClick={handleAddNewProduct}
                    >Donate Now</button>
                </div>
            </div>
        </div>
    );
}

export default DetailsWidget;