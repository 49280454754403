const standWithMatwSection = {
    title: {
        main: {
            en: 'Stand with MATW in',
            fr: 'Soutenez la MATW dans.',
            ar: 'تضامن مع MATW'
        },
        highlighted: {
            en: 'ending poverty.',
            fr: 'sa lutte contre la pauvreté',
            ar: 'لإنهاء الفقر.'
        }

    },
    description: {
        section1: {
            en: 'Food and clean water. These are among life’s most basic necessities and yet there are more than 820 million people going to bed hungry every night, and more than 2 billion who lack access to safe drinking water.',
            fr: `De la nourriture et de l'eau potable. Il s'agit des besoins les plus élémentaires de la vie et pourtant, plus de 820 millions de personnes se couchent le ventre vide chaque soir et plus de 2 milliards n'ont pas accès à l'eau potable.`,
            ar: 'الغذاء والمياه النظيفة. هذه من بين أساسيات الحياة ومع ذلك، هناك أكثر من 820 مليون شخص يذهبون إلى الفراش جائعين كل ليلة، وأكثر من 2 مليار شخص يعانون من نقص في الوصول إلى مياه شرب آمن'
        },
        section2: {
            en: 'Imagine living each day uncertain about where your next meal will come from. Or whether the water you give your children will make them sick.',
            fr: `Imaginez que vous viviez chaque jour dans l'incertitude de savoir d'où viendra votre prochain repas ; ou si l'eau que vous donnez à vos enfants les rendra malades.`,
            ar: 'تخيل أن تعيش كل يوم في عدم اليقين حول مصدر وجبتك التالية، أو ما إذا كانت المياه التي تقدمها لأطفالك ستجعلهم مرضى.'
        },
        section3: {
            en: 'This is the reality for millions living in impoverished communities around the world - especially those suffering from the effects of conflict and natural disasters.',
            fr: `Telle est la réalité pour des millions de personnes vivant dans des communautés appauvries à travers le monde, en particulier celles qui souffrent des effets des conflits et des catastrophes naturelles.`,
            ar: 'هذا هو الواقع الذي يواجهه ملايين الأشخاص الذين يعيشون في المجتمعات المعدمة حول العالم - خاصةً الذين يعانون من آثار النزاعات والكوارث الطبيعية.'
        },
        section4: {
            en: 'Our Food and Water Crisis fund is dedicated to ensuring access to clean water and delivering urgent food to the most vulnerable in the most difficult-to-reach areas. With your continued support, we’re not only able to provide urgent relief, we’re able to provide hope.',
            fr: `Notre fonds pour la crise de l'eau et de la nourriture est destiné à garantir l'accès à l'eau potable et à fournir des denrées alimentaires d'urgence aux plus vulnérables dans les zones les plus difficiles d'accès. Grâce à votre soutien continu, nous sommes non seulement en mesure de fournir une aide d'urgence, mais aussi de donner de l'espoir.`,
            ar: 'هذا هو الواقع الذي يواجهه ملايين الأشخاص الذين يعيشون في المجتمعات المعدمة حول العالم - خاصةً الذين يعانون من آثار النزاعات والكوارث الطبيعية.'
        },
    }
}

const helpUsReachMore = {
    title: {
        main1: {
            en: "More than",
            fr: "Plus de",
            ar: "اكثر من"
        },
        highlighted1: {
            en: "828 million",
            fr: "828 millions",
            ar: "828 مليون"
        },
        main2: {
            en: "people sleep hungry.",
            fr: "de personnes se couchent le ventre vide.",
            ar: "شخص يعانون من الجوع وينامون جائعين."
        },
        highlighted2: {
            en: "You can help change that.",
            fr: "Vous pouvez contribuer à changer cela.",
            ar: "يمكنك المساعدة في تغيير ذلك."
        },
        main3: {
            en: "Help us reach more now.",
            fr: "Aidez-nous à venir en aide à un plus grand nombre de personnes maintenant.",
            ar: "ساعدنا في الوصول إلى المزيد الآن."
        }

    },
    description: {
        section1: {
            en: 'Whether it be people in countries riddled with conflict, families in disaster-prone areas, or children in impoverished communities, hunger doesn’t differentiate. It kills. Your Sadaqah could be the difference between life and death for an orphan in Palestine, a family in Lebanon, or a widow in Pakistan. As the cost of essential food supplies continues to soar across the globe, our ummah needs to help ensure their survival.',
            fr: `Qu'il s'agisse de personnes vivant dans des pays déchirés par des conflits, de familles dans des zones exposées aux catastrophes ou d'enfants vivant dans des communautés appauvries, la faim n'épargne personne ; elle tue. Votre Sadaqah pourrait faire la différence entre la vie et la mort pour un orphelin en Palestine, une famille au Liban ou une veuve au Pakistan. Alors que le coût des denrées alimentaires essentielles continue de grimper en flèche dans le monde entier, notre oumma doit contribuer à assurer leur survie.`,
            ar: 'سواء كانوا في بلدان ممزقة بالنزاعات، أو عائلات في مناطق تعرضت للكوارث، أو أطفال في مجتمعات معدمة، الجوع لا يفرق بينهم، بل يقتل. صدقتك يمكن أن تكون الفارق بين الحياة والموت ليتيم في فلسطين، أو عائلة في لبنان، أو أرملة في باكستان. مع ارتفاع تكاليف الإمدادات الغذائية الأساسية في جميع أنحاء العالم، تحتاج أمتنا إلى المساعدة لضمان بقائهم.'
        },
        section2: {
            en: 'Our MATW team is working tirelessly in more than 30 countries around the world to reach those most in need. Help us ensure that the most vulnerable have what they need to get them. Be the answer to their duas. Help us help them survive.',
            fr: `Notre équipe MATW travaille sans relâche dans plus de 30 pays à travers le monde pour venir en aide à ceux qui en ont le plus besoin. Aidez-nous à faire en sorte que les plus vulnérables aient ce dont ils ont besoin pour s'en sortir. Soyez la réponse à leurs douâa. Aidez-nous à assurer leur survie.`,
            ar: 'فريقنا في MATW يعمل بلا كلل في أكثر من 30 دولة حول العالم للوصول إلى أولئك الأكثر احتياجًا. ساعدنا في ضمان أن الأشد ضعفًا يحصلون على ما يحتاجونه للبقاء. كن الجواب على دعواتهم. ساعدنا لنساعدهم على البقاء.'
        },
    }
}

const safeAndCleanDrinkWaterSection = {
    title: {
        main1: {
            en: 'Last year, our MATW team delivered more than of ',
            fr: `L'année dernière, notre équipe MATW a fourni plus de`,
            ar: 'في العام الماضي، قدم فريقنا في MATW أكثر من'
        },
        highlighted: {
            en: '30,000,000L',
            fr: `30 000 000`,
            ar: '30,000,000'
        },
        main2: {
            en: ' safe and clean drinking water.',
            fr: `de litres d'eau potable.`,
            ar: 'لتر من المياه النظيفة والآمنة للشرب.'
        },
    },
    description: {
        en: 'From disaster zones to regions gripped in the midst of conflict, our MATW team has worked tirelessly to provide clean drinking water in form of filtration units, rainwater harvesting systems, water tanks, bottles and more. Clean water is a basic human right and we won’t stop until we reach as many people as possible.',
        fr: `Des zones sinistrées aux régions déchirés par des conflits, notre équipe MATW a travaillé sans relâche pour fournir de l'eau potable sous forme d'unités de filtration, de systèmes de collecte d'eau de pluie, de réservoirs d'eau, de bouteilles, etc. L'eau potable est un droit humain fondamental et nous ne nous arrêterons pas tant que nous n'aurons pas atteint le plus grand nombre possible de personnes qui en ont besoin.`,
        ar: 'من مناطق الكوارث إلى المناطق المنكوبة بالنزاعات، عمل فريقنا في MATW بلا كلل لتوفير مياه الشرب النظيفة من خلال وحدات الترشيح، وأنظمة جمع مياه الأمطار، وخزانات المياه، وزجاجات وأكثر من ذلك. المياه النظيفة هي حق أساسي للإنسان ولن نتوقف حتى نصل إلى أكبر عدد ممكن من الناس.'
    }
}

export { standWithMatwSection, helpUsReachMore, safeAndCleanDrinkWaterSection };