import React, { useEffect, useState, useRef } from 'react';
import Layout from '../../components/general/layout';
import OrphanCrisisWidget from '../orphans/OrphanCrisesWidget';
import CrisisWidget from './components/crisisWidget';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import { selectProducts } from '../../store/products';
import { CustomButton, OurPromiseSection } from '../../components/general';
import BangladeshTabs from './components/bangladesh-tabs';
import MaxWidthContainer from '../../components/general/maxWidthContainer';
import useHandleAddProduct from '../../utils/handleAddProduct';
// import ImagesSection from './components/common-sections/images';
import FAQSectionOtherPalestinePage from './components/common-sections/faqs';
import { bangladeshEmergency } from './components/faqs';
import BottomNav from '../orphans/bottomNav';
import useWindowSize from '../../hooks/general/useWindowSize';
import BangladeshImagesSection from './components/bangladeshImages';

const BangladeshAppeal = () => {
  const banner = 'https://cdn.matwproject.org/images/banners/bangladesh-banner.webp';
  const bannerMobile = 'https://cdn.matwproject.org/images/banners/bangladesh-banner-mobile.jpg';
  const matwGroundImg = 'https://cdn.matwproject.org/images/landings/crisis/bangladesh-page-image.webp';

  const products = useSelector(state => selectProducts(state));
  const [foodAidProducts, setFoodAidProducts] = useState([0, 0, 0]);
  const [medicalAidProducts, setMedicalAidProducts] = useState([0, 0, 0]);
  const [orphansSupportProducts, setOrphansSupportProducts] = useState([0]);
  const [whereMostNeededProducts, setWhereMostNeededProducts] = useState([0]);
  const [matwOnGroundProduct, setMatwOnGroundProduct] = useState();
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const seo = SeoData[selectedcountryCode];
  const [isLoaded, setIsLoaded] = useState(false);
  const [FAQs, setFAQs] = useState(bangladeshEmergency);
  const [addProduct] = useHandleAddProduct();
  const { width } = useWindowSize();
  const isMobile = width <= 768;

  const getAllProducts = () => {
    setIsLoaded(true);
    let foodAidProductsList = [];
    let medicalAidProductsList = [];
    let orphansSupportProductsList = [];
    let whereMostNeededProductsList = [];
    let matwOnGroundProductItem;
    products.forEach(item => {
      item.sub_categories.forEach(inner_item => {
        inner_item.products.forEach(inner_item_product => {
          if (inner_item_product.creator === 'EMR-BAN-RBB-2024-01') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: '40 Kg Rice Bag',
            });
            foodAidProductsList[0] = inner_item_product;
          } else if (inner_item_product.creator === 'EMR-BAN-FPB-2024-02') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'Emergency Food Pack',
            });
            foodAidProductsList[1] = inner_item_product;
          } else if (inner_item_product.creator === 'EMR-BAN-BFB-2024-07') {
            foodAidProductsList[2] = inner_item_product;
          } else if (inner_item_product.creator === 'EMR-BAN-DSB-2024-06') {
            medicalAidProductsList[0] = inner_item_product;
          } else if (inner_item_product.creator === 'EMR-BAN-HCB-2024-05') {
            medicalAidProductsList[1] = inner_item_product;
          } else if (inner_item_product.creator === 'EMR-BAN-EMB-2024-08') {
            orphansSupportProductsList[0] = inner_item_product;
          } else if (inner_item_product.creator === 'EMR-BAN-BTS-2024-04') {
            orphansSupportProductsList[1] = inner_item_product;
          } else if (inner_item_product.creator === 'EMR-BAN-ORP-2024-03') {
            orphansSupportProductsList[2] = inner_item_product;
          } else if (inner_item_product.creator === 'MKD-MN-001') {
            matwOnGroundProductItem = inner_item_product;
          } else if (inner_item_product.creator === 'EMR-BAN-ALA-2024-00') {
            whereMostNeededProductsList[0] = inner_item_product;
          }
        });
      });
    });

    foodAidProductsList = foodAidProductsList.filter(n => n);
    medicalAidProductsList = medicalAidProductsList.filter(n => n);
    orphansSupportProductsList = orphansSupportProductsList.filter(n => n);
    whereMostNeededProductsList = whereMostNeededProductsList.filter(n => n);

    // console.log(matwOnGroundProductItem);

    setFoodAidProducts(foodAidProductsList);
    setMedicalAidProducts(medicalAidProductsList);
    setOrphansSupportProducts(orphansSupportProductsList);
    setWhereMostNeededProducts(whereMostNeededProductsList);
    setMatwOnGroundProduct(matwOnGroundProductItem);
  };

  useEffect(() => {
    // console.log(selectedCurrencyStore);

    products && Object.keys(products).length && !isLoaded && getAllProducts();

    setFAQs(bangladeshEmergency);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  const faqsRef = useRef(null);
  const moreInfoRef = useRef(null);
  const appealsRef = useRef(null);
  const [activeSection, setActiveSection] = useState('');
  const [showScrollMore, setShowScrollMore] = useState(false);
  const startRef = useRef(null);
  const endRef = useRef(null);

  const scrollToRef = ref => {
    if (ref.current) {
      // Calculate offset position above the element
      const offsetPosition = ref.current.offsetTop - 150; // Adjust 100 pixels above the element
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  const handleScroll = entries => {
    entries.forEach(entry => {
      // console.log(entry);

      if (entry.isIntersecting) {
        setActiveSection(entry.target.id);
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleScroll, {
      threshold: 0.3,
    });

    if (faqsRef.current) observer.observe(faqsRef.current);
    if (moreInfoRef.current) observer.observe(moreInfoRef.current);
    if (appealsRef.current) observer.observe(appealsRef.current);

    return () => {
      if (faqsRef.current) observer.unobserve(faqsRef.current);
      if (moreInfoRef.current) observer.unobserve(moreInfoRef.current);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (appealsRef.current) observer.unobserve(appealsRef.current);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (startRef.current && endRef.current) {
        const startPosition = startRef.current.getBoundingClientRect().top;
        const endPosition = endRef.current.getBoundingClientRect().bottom;
        const windowHeight = window.innerHeight;

        if (startPosition < windowHeight && endPosition > 870) {
          setShowScrollMore(true);
        } else {
          setShowScrollMore(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Layout className={`${!isMobile && 'bg-[#fff]'}`}>
      {seo && (
        <Helmet>
          <title>{seo['bangladesh-appeal']['title']}</title>
          <meta name="title" content={seo['bangladesh-appeal']['title']} />
          <meta name="description" content={seo['bangladesh-appeal']['description']} />
        </Helmet>
      )}

      <section className="bg-[#DFEEF2] sticky top-16 z-30 md:hidden p-2 md:p-4">
        <OrphanCrisisWidget
          ramadanMobileWidget
          className={'bg-[#DFEEF2] h-auto'}
          fixPrice={false}
          defaultProduct={{
            creator: 'EMR-BAN-ALA-2024-00',
            label: 'Bangladesh Emergency Appeal',
            value: 122,
          }}
        />
      </section>
      <section className="flex justify-center">
        <img src={banner} alt="banner" className="max-[500px]:hidden w-full" />
        <img src={bannerMobile} alt="banner" className="min-[501px]:hidden w-full" />
      </section>
      <section className="bg-[#DFEEF2]">
        <section className="max-w-[1440px] mx-auto">
          <div className="w-full hidden lg:mx-auto px-4 xl:px-10 py-4 md:py-6 md:flex justify-center items-center">
            <CrisisWidget
              floating
              className={'bg-[#DFEEF2] px-6 h-auto pt-10'}
              defaultProduct={{
                creator: 'EMR-BAN-ALA-2024-00',
                label: 'Bangladesh Emergency Appeal',
                value: 122,
              }}
            />
          </div>
        </section>
      </section>
      <section id="appeals" ref={appealsRef}>
        {/* {console.log("on bang appeal=",whereMostNeededProducts)} */}
        <BangladeshTabs
          foodAidProducts={foodAidProducts}
          medicalAidProducts={medicalAidProducts}
          orphansSupportProducts={orphansSupportProducts}
          whereMostNeededProducts={whereMostNeededProducts}
        />
      </section>
      <OurPromiseSection childClasses={'!bg-[#fff]'} />
      <section
        className="xl:px-10 pt-5 sm:pt-12 bg-[#fff]"
        id="more-info"
        ref={element => {
          moreInfoRef.current = element;
          startRef.current = element;
        }}
      >
        <MaxWidthContainer className="!max-w-[1440px] !px-4 md:!px-6">
          <div className="flex">
            <div className="text-left font-brandingBold sm:text-center font-bold text-[#093686] leading-[30px] sm:leading-[55px] text-2xl sm:text-5xl w-full sm:w-10/12 xs:mb-4 md:mb-0">
              <span className="text-[#F60362] mr-2">Devastating floods</span>
              ravage Bangladesh. Your crucial donations will help us save lives. <span className="text-[#F60362] mr-2">Donate Now.</span>
              <br />
            </div>
          </div>
          <div className="pt-2 sm:pt-10 leading-5">
            <div className="text-[#78716C] leading-6 text-[18px] text-left  font-brandingMedium">
              At least 20 people have lost their lives and more than 5.2 million have been affected in the recent floods that have swept through
              Bangladesh
              <br />
              <br />
              Triggered by a relentless monsoon, the ongoing flash floods have left millions stranded and in desperate need of relief. Nearly 300,000
              people have been forced into emergency shelters.
              <br />
              <br />
              With roads cut off and communication networks down, those who are most vulnerable are in desperate need of urgent relief.
              <br />
              <br />
              Our MATW Project team is on the ground responding by providing life-saving essentials including food, medical support and more. 
              <br />
              <br />
              Help us reach those in need. Donate Now.
            </div>
          </div>
        </MaxWidthContainer>
      </section>
      <section id="matw-ground" className="bg-[#fff]">
        <MaxWidthContainer className="!max-w-[1440px] !px-4 md:!px-6">
          <div className="w-full flex flex-wrap px-4 md:px-0 pt-4- md:pt-8 pt-8 pb-8 md:mb-0">
            <div className="basis-full md:basis-1/2">
              <img src={matwGroundImg} alt="" className="max-h-[490px] rounded-lg shadow-lg" />
            </div>
            <div className="basis-full md:basis-1/2">
              <h1 className="text-2xl font-medium md:text-4xl text-[#253B7E] mt-4 md:mt-0 mb-4 md:mb-8-">MATW on the ground. </h1>
              <p className="text-[18px] text-[#78716C] font-light">
                The floodwaters in Bangladesh have destroyed homes and critical infrastructure. Acres of agricultural land have been submerged leaving
                residents struggling to survive due to limited access to food, clean water and medical support. 
                <br />
                <br />
                MATW has been on the ground in Bangladesh working to support orphaned children and those who are most vulnerable. 
                <br />
                <br />
                With a supply store in place, our team was ready to distribute immediate relief items and are responding with food and education
                essentials as well as makeshift medical support.
                <br />
                <br />
                MATW is addressing immediate life-saving needs and plans to intensify our efforts to reach more people. MATW is committed to
                delivering essentials within these communities throughout the crisis, but we cannot do it alone. For flood survivors including
                orphans, widows and the elderly, your support now has never been more crucial. 
                <br />
                <br />
                Help our MATW deliver urgent relief items with your Sadaqah and Zakat.
              </p>
              <CustomButton
                onClicked={() => {
                  matwOnGroundProduct && addProduct({ product: matwOnGroundProduct, currency: selectedCurrencyStore || 'AUD'});
                }}
                title={'Donate Now'}
                className="!block !w-auto text-white text-sm md:text-lg transition-all mt-2 duration-200 bg-[#f60362] hover:bg-[#00a3da] h-[48px] px-4 sm:px-6 rounded-md"
              />
            </div>
          </div>
        </MaxWidthContainer>
      </section>
      <section className="bg-[#F8F8F8] pt-5 pb-5 sm:py-8">
        {/* /* --------------------------------- Images --------------------------------- */}
        <BangladeshImagesSection />
        {/* /* -------------------------------------------------------------------------- */}
      </section>
      <section
        id="faqs"
        ref={element => {
          faqsRef.current = element;
          endRef.current = element;
        }}
        className="bg-[#fff]"
      >
        <FAQSectionOtherPalestinePage FAQs={FAQs} lang="en" faqTitle="Bangladesh Donations"></FAQSectionOtherPalestinePage>
      </section>
      {showScrollMore && (
        <>
          <div
            className="fixed xs:bottom-12 md:bottom-0 left-1/2 transform -translate-x-1/2 w-full text-center text-[#14A2DC] text-[14px] font-medium py-2"
            style={{ zIndex: 999, background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0.01%, #FFFFFF 100%)' }}
          >
            <div className="text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mx-auto"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
              </svg>
            </div>
            scroll more
          </div>
        </>
      )}
      <BottomNav
        activeSection={activeSection}
        scrollToRef={scrollToRef}
        faqsRef={faqsRef}
        moreInfoRef={moreInfoRef}
        appealsRef={appealsRef}
        displayingPositionY={100}
      />
    </Layout>
  );
};

export default BangladeshAppeal;
