import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../store/user';
import { Helmet } from 'react-helmet';
import { selectLastOrders, selectProducts } from '../../store/products';
import AppealLevelGiving from './components/appealLevelGiving';
// import services from '../../services';
import Layout from '../../components/general/layout';
// import Skeleton from 'react-loading-skeleton';
// import { appealProductsCodes, endDateOfAppealCampaign, startDateOfAppealCampaign } from './details';
import CustomButton from '../../components/general/button';
import useHandleAddProduct from '../../utils/handleAddProduct';
import SelectCurrency from '../../components/general/selectCurrency';
import { BASKET_CACHE_KEY, HandleLocalStorage } from '../../utils/handleLocalStorage';
import ShowCurrencies from '../../utils/showCurrencies';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { CloseIcon } from '../../components/general/customIcons';
import { useNavigate, useParams } from 'react-router-dom';
import { generateLinkWithLang } from '../../utils/linkGenerator';
import getSymbolFromCurrency from 'currency-symbol-map';

const MATWAppealLandingPage = ({ lang = 'en', campaign = 'palestine' }) => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [addProduct, editProduct, removeProduct] = useHandleAddProduct();
  const navigate = useNavigate();
  const products = useSelector(state => selectProducts(state));
  const orders = useSelector(state => selectLastOrders(state));
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [givingProducts, setGivingProducts] = useState([0, 0, 0, 0, 0, 0, 0])
  const [currentgivingProducts, setCurrentGivingProducts] = useState([0, 0, 0, 0, 0, 0, 0])
  const [currentProduct, setCurrentProduct] = useState();
  const [selectedGivingProducts, setSelectedGivingProducts] = useState({
    products: [],
    total: 0,
  });
  const [isMinimized, setIsMinimized] = useState(false);
  const [showMoreItems, setShowMoreItems] = useState(false);
  const paramLang = useParams(); // Get the language from the URL
  // const [orders, setOrders] = useState([]);
  /* ------------------------------ Start methods ----------------------------- */
  const getAllProducts = _prds => {
    // setIsLoaded(true);
    if (products && Object.keys(products).length) {
      let tempGivingProducts = [];
      let tempCurrentProduct = null;
      products.map(item => {
        item.sub_categories.map(inner_item => {
          if (inner_item.id === 65) { // Palestine Emergency
            inner_item.products.map(inner_item_product => {
              inner_item_product = Object.assign({}, inner_item_product, {
                total_count: 0,
                total_sale: 0,
                goal: 500000,
                campaign: 'palestine',
              });
              _prds.length && _prds.map(_prdItem => {
                if (_prdItem.product_id === inner_item_product.id) {
                  inner_item_product = Object.assign({}, inner_item_product, {
                    total_count: _prdItem.total_count,
                    total_sale: _prdItem.total_sale,
                  });
                }
                return _prdItem;
              });
              if (inner_item_product.creator === 'MA-PAL-GL-1-001') {
                if (campaign === 'palestine') {
                  inner_item_product.alternativeName = 'Gaza Can’t Wait!'
                  inner_item_product.description = 'For almost a year, too many people have suffered for far too long due to the devastation the conflict in Palestine has left in its wake. The lives of children and their families continue to be uprooted, leaving many orphaned with no one to protect them.'
                  tempCurrentProduct = inner_item_product
                }
              }
              tempGivingProducts.push(inner_item_product);
              return inner_item_product;
            });
          }
          if (inner_item.id === 66) { // Palestine Refugee Resettlement
            inner_item.products.map(inner_item_product => {
              inner_item_product = Object.assign({}, inner_item_product, {
                total_count: 0,
                total_sale: 0,
                goal: 500000,
                campaign: 'egyptian',
              });
              _prds.length && _prds.map(_prdItem => {
                if (_prdItem.product_id === inner_item_product.id) {
                  inner_item_product = Object.assign({}, inner_item_product, {
                    total_count: _prdItem.total_count,
                    total_sale: _prdItem.total_sale,
                  });
                }
                return _prdItem;
              });
              if (inner_item_product.creator === 'MA-PAL-GL-3-001') {
                if (campaign === 'egyptian') {
                  inner_item_product.alternativeName = 'Help Rebuild Lives for Palestinians escaping conflict'
                  inner_item_product.description = 'Imagine losing your belongings, your home, loved ones - everything you’ve ever known. That is the reality for more than 100,000 Palestinians who have fled to neighbouring countries.'
                  tempCurrentProduct = inner_item_product
                }
              }
              tempGivingProducts.push(inner_item_product);
              return inner_item_product;
            });
          }
          if (inner_item.id === 73) { // Limbs of hope
            inner_item.products.map(inner_item_product => {
              inner_item_product = Object.assign({}, inner_item_product, {
                total_count: 0,
                total_sale: 0,
                goal: 1000000,
                campaign: 'limbs-of-hope',
              });
              _prds.length && _prds.map(_prdItem => {
                if (_prdItem.product_id === inner_item_product.id) {
                  inner_item_product = Object.assign({}, inner_item_product, {
                    total_count: _prdItem.total_count,
                    total_sale: _prdItem.total_sale,
                  });
                }
                return _prdItem;
              });
              if (inner_item_product.creator === 'EMR-PAL-MASU-005') {
                if (campaign === 'limbs-of-hope') {
                  inner_item_product.alternativeName = 'GAZA: Limbs of Hope'
                  inner_item_product.description = 'Your generosity can transform a life. By donating to our Prosthetic Limb Fundraise, you’re helping to provide a prosthetic limb to someone in need.'
                  tempCurrentProduct = inner_item_product
                }
              }
              tempGivingProducts.push(inner_item_product);
              return inner_item_product;
            });
          }
          return inner_item;
        });
        return item;
      });
      tempGivingProducts = tempGivingProducts.filter(n => n);
      tempGivingProducts = tempGivingProducts.sort((a, b) => a[selectedCurrencyStore.toLowerCase()] - b[selectedCurrencyStore.toLowerCase()])
      // console.log('set-AppealProducts=', tempCurrentProduct);
      setGivingProducts(tempGivingProducts);
      setCurrentGivingProducts(tempGivingProducts)
      setCurrentProduct(tempCurrentProduct)
    }
  };

  // const getOrders = async (_currency) => {
  //   let req = {
  //     products: appealProductsCodes,
  //     // limit: 20,
  //     currency: _currency || 'AUD',
  //     start_date: startDateOfAppealCampaign,
  //     end_date: endDateOfAppealCampaign
  //     // start_date: '2024-02-23'
  //   }
  //   try {
  //     const response = await services.getLatestOrders(req);
  //     // console.log('get orders=', products, response.data);
  //     // setAppealProducts(response.data)
  //     // getAllProducts(response.data)
  //     setOrders(response.data);
  //   } catch (error) {
  //     console.error('error========', error);
  //   }
  // };
  const handleAddProduct = () => {
    // const basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
    // console.log("basketStatesFromLocal Storage=", basketStatesFromLocalStorage)
    // addProduct(currentProduct, selectedCurrency, 'single', false, true, basketStatesFromLocalStorage)
    addProduct({ product: currentProduct, currency: selectedCurrency })
  }
  const handleSelectedGiving = () => {
    let basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
    let tempSelectedGiving = [];
    let tempTotalGiving = 0;
    let temp = [];
    givingProducts.map((item, index) => {
      basketStatesFromLocalStorage &&
        basketStatesFromLocalStorage.products.map(innerItem => {
          if (innerItem.product && innerItem.product.creator === item.creator) {
            item = Object.assign({}, item, {
              isAdded: true,
              quantity: innerItem.quantity,
            });
            tempTotalGiving += (item.quantity || 1) * item[selectedCurrencyStore.toLowerCase()];
            tempSelectedGiving.push(item);
          }
          return innerItem;
        });
      temp[index] = item;
      return item;
    });
    console.log("tempSelectedGiving=", tempSelectedGiving)
    tempSelectedGiving.length === 1 && setIsMinimized(false);
    setSelectedGivingProducts({ ...selectedGivingProducts, products: tempSelectedGiving, total: tempTotalGiving });
    temp = temp.map(item => item).filter(item => item.campaign === campaign)
    setCurrentGivingProducts(temp);
  }
  const handleMinimize = () => {
    setIsMinimized(!isMinimized);
  };
  const handleCurrency = (currency, tempBasket) => {
    let _currency = currency;
    if (tempBasket.products.length && tempBasket.products[0].product) {
      tempBasket.products.map(item => {
        item.currency = _currency;
        item.product.currency = _currency;
        item.total = item.quantity * item.product[_currency];
        item.amount = item.product[_currency];
        return item;
      });
      tempBasket.currency = _currency;
      HandleLocalStorage(tempBasket);
    } else {
      tempBasket.currency = _currency;
      HandleLocalStorage(tempBasket);
    }
  };
  const handleRemoveProduct = (prd) => {
    removeProduct(prd)
    handleSelectedGiving();
  };
  const handleBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate(generateLinkWithLang('/', paramLang.lang), { replace: true }); // the current entry in the history stack will be replaced with the new one with { replace: true }
    }
  };
  const ShowCurrencies2 = (currency, _price, showAbbr = true) => {
    let tempCurrency = currency && currency.toUpperCase();
    let price = Number(_price);
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    };
    if (price && (Number(price) % 1 !== 0)) {
      price = Number(price).toLocaleString('en', options);
    }
    let _final = ''
    price = price ? price.toLocaleString() : 0;
    switch (tempCurrency) {
      case 'GBP':
        _final =`${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`
        break;
      case 'EUR':
        _final =`${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`
        break;
      case 'MYR':
        _final =`${price}`
        break;
      case 'IDR':
        _final =`Rp${price}`
        break;
      case 'AED':
        _final =`Dh${price}`
        break;
      default:
        _final =`$${price}`
        break;
    }
    return _final
  };
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    // console.log("pathname==",window.location.pathname,isLoaded, selectedCurrencyStore, products)
    console.log("selectedCurrencyStore==",selectedCurrencyStore)
    // setCurrentProduct(null);
    // getOrders(selectedCurrencyStore);
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
    if (givingProducts.length) {
      let tempGivingProducts = givingProducts.sort((a, b) => a[selectedCurrencyStore.toLowerCase()] - b[selectedCurrencyStore.toLowerCase()])
      console.log("tempGivingProducts==",tempGivingProducts)
      setCurrentGivingProducts(tempGivingProducts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrencyStore]);
  useEffect(() => {
    if (orders && products && Object.keys(products).length) {
      getAllProducts(orders)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders, products, navigate]);
  // useEffect(() => {
  //   if(givingProducts.length) {
  //     let tempGivingProducts = givingProducts.sort((a, b) => a[selectedCurrencyStore.toLowerCase()] - b[selectedCurrencyStore.toLowerCase()])
  //     setGivingProducts(tempGivingProducts);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedCurrencyStore]);
  useEffect(() => {
    let basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
    if (basketStatesFromLocalStorage && basketStatesFromLocalStorage.currency !== selectedCurrency) {
      handleCurrency(selectedCurrency, basketStatesFromLocalStorage);
    }
    if (givingProducts[0]) {
      handleSelectedGiving();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [givingProducts, selectedCurrency]);
  /* -------------------------------------------------------------------------- */

  return (
    <Layout className="!bg-white" isAppealPage>
      <Helmet>
        <title>{`${currentProduct ? currentProduct.name : ''} | MATW Project`}</title>
        <meta name="title" content={`${currentProduct ? currentProduct.name : ''} | MATW Project`} />
        <meta name="description" content={`${currentProduct ? currentProduct.description : ''}`} />
        <div itemscope itemtype="http://schema.org/Product">
          <meta itemprop="brand" content="MATW-Project" />
          <meta itemprop="name" content={`${currentProduct ? currentProduct.name : ''} - MATW Project`} />
          <meta itemprop="description" content={`${currentProduct ? currentProduct.description : ''}`} />
          <meta itemprop="productID" content={`${currentProduct ? currentProduct.creator : ''}`} />
          <meta itemprop="url" content={window.location.href} />
          <meta itemprop="image" content={currentProduct ? currentProduct.image_link : ''} />
          <meta itemprop="price" content={currentProduct ? currentProduct.unit_amount : '100'} />
        </div>
      </Helmet>
      <section className="w-full md:container md:mx-auto grid grid-cols-2 gap-2 pb-4 md:py-8">
        <div className="col-span-2 px-6 py-3 lg:pl-2 lg:pr-0 md:pt-0 md:pb-0">
          <h3 className="w-full text-DarkerBlue text-2xl lg:text-3xl xl:text-4xl font-medium my-3 md:my-2 md:leading-none">
            {/* {currentProduct ? currentProduct.alternativeName || currentProduct.name : <Skeleton height={48} />} */}
            {campaign === 'palestine'
              ? 'Gaza Can’t Wait!'
              : campaign === 'egyptian' ? 'Help Rebuild Lives for Palestinians escaping conflict' : 'GAZA: Limbs of Hope'
            }
          </h3>
          <div className="w-full relative">
            <div className={`my-2 relative overflow-hidden h-auto`}>
              <p className={`text-[#78716C] text-xs sm:text-sm overflow-hidden transition-all duration-200 min-h-[79px]-`}>
                {/* {currentProduct.short_description || currentProduct.description} */}
                {campaign === 'palestine'
                  ? 'For almost a year, too many people have suffered for far too long due to the devastation the conflict in Palestine has left in its wake. The lives of children and their families continue to be uprooted, leaving many orphaned with no one to protect them.'
                    : campaign === 'egyptian'
                        ? 'Imagine losing your belongings, your home, loved ones - everything you’ve ever known. That is the reality for more than 100,000 Palestinians who have fled to neighbouring countries.'
                        : (
                            <>
                              Deep inside the ruins of Gaza lies a problem not known to many. More than 94,000
                              Palestinians have been severely injured since the beginning of the most recent conflict on
                              October 7, 2023.
                              <br/><br/>
                              Among them, thousands have become amputees - losing an arm, a leg or in some cases all
                              their limbs. <i>Imagine</i> not being able to stand in Salat, or wrap your arms around your loved
                              ones!
                              <br/><br/>
                              The people of Palestine have already lost so much. They’ve been forced from their homes,
                              lost their livelihoods, their belongings and their loved ones - don’t let them lose their
                              dignity or hope.
                              <br/><br/>
                              <strong>As the official named partner of the Jordan Hashemite Charity Organisation,
                                Muslims Around The World Project (MATW Project), will be providing prosthetic limbs in
                                Gaza to amputees through a new ground-breaking technology that can fit a limb within one
                                hour!</strong>
                              <br/><br/>
                              <strong>A Sadaqah Jariyah like this not only has the power to save lives but will also reap
                                immense rewards for your Akhirah Insha’Allah!</strong>
                              <br/><br/>
                              <strong>Help us provide Limbs of Hope for Gazans in need and restore dignity and hope.
                                Donate Now.</strong>
                            </>
                        )
                }
              </p>
            </div>
          </div>
        </div>
        <div className="col-span-2 md:col-span-1 p-4 md:p-0 flex">
          {/* {currentProduct
            ? <>
              <CustomButton
                onClick={() => handleAddProduct()}
                title={'Donate Now'}
                type="button"
                className={`!h-[38px] w-full sm:w-auto mr-2`} />
              <SelectCurrency onChangeCurrency={(e) => setSelectedCurrency(e)} className="sm:w-auto" />
            </>
            : <Skeleton height={43} />
          } */}
          <CustomButton
            onClick={() => handleAddProduct()}
            title={'Donate Now'}
            type="button"
            className={`!h-[38px] w-full sm:w-auto mr-2`} />
          <SelectCurrency onChangeCurrency={(e) => setSelectedCurrency(e.value)} className="sm:w-auto" />
        </div>
      </section>
      {/* ------------------- Products Section -------------------- */}
      <section dir={lang === 'ar' ? 'rtl' : 'ltr'}>
        <div className={`md:container w-full mx-auto px-4 md:px-0 py-4 md:py-8`}>
          <AppealLevelGiving showAllBoxes givingProducts={currentgivingProducts} title='Select a Giving Level' campaign={campaign} handleSelectedGivingProps={() => handleSelectedGiving()} />
        </div>
      </section>
      {selectedGivingProducts.products.length ? (
        <div className="fixed bottom-0 sm:hidden- [&>div]:w-full z-20- z-[2200000000] shadow-[rgba(189,189,189,0.5)_0px_-15px_25px_-1px] w-full left-0 p-2 bg-LighterBlue">
          <div className="flex flex-col gap-1 items-center w-full justify-around mx-auto p-2 rounded-lg bg-white">
            <div className={`flex w-full flex-col border-b border-stone-300 ${isMinimized ? '' : 'mb-1'} pb-1 cursor-pointer`} onClick={handleMinimize}>
              <h2 className={`text-DarkerBlue text-lg md:text-2xl w-full font-bold w-100 flex justify-between`}>
                Your Giving Amount{' '}
                <ChevronDownIcon className={`w-4 h-4 cursor-pointer text-stone-600 transition-all duration-300 ${isMinimized ? ' transform rotate-180' : ''}`} />
              </h2>
              <div className="flex items-center">
                <span className="text-DarkerBlue text-sm">
                  {selectedGivingProducts.products.length} items in Cart
                </span>
                <span className="text-LightBlue text-sm cursor-pointer ml-2" onClick={() => navigate(generateLinkWithLang('/checkout', paramLang.lang))}>
                  View Cart
                </span>
              </div>
            </div>
            <div className={`transition-all duration-300 w-full ${isMinimized ? 'h-0' : 'h-auto'} overflow-hidden`}>
              {/* <div className={`transition-all duration-300 overflow-hidden w-full`} style={{ height: `${isMinimized ? '0px' : `${(selectedGivingProducts.products.length > 1 ? selectedGivingProducts.products.length * 32 : selectedGivingProducts.products.length * 36) + 29}px`}` }}> */}
              <div className="flex justify-between items-center flex-col py-1 w-full border-b border-stone-200 max-h-[160px] overflow-y-auto">
                {selectedGivingProducts.products.map((item, index) => {
                  if (showMoreItems) {
                    return (
                      <div key={`giving${index}`} className="w-full flex items-center justify-between mb-1">
                        <div className="text-stone-400 text-xs flex items-center">
                          <CloseIcon className="w-4 h-4 text-stone-400 mr-2 cursor-pointer" onClick={() => handleRemoveProduct(item)} />
                          <p className="text-stone-400 text-xs">{item.name}</p>
                        </div>
                        <p className="text-xs text-DarkerBlue">
                          {ShowCurrencies2(selectedCurrencyStore, item.quantity * item[selectedCurrencyStore.toLowerCase()], false)}
                        </p>
                      </div>
                    );
                  } else {
                    return index < 2 && (
                      <div key={`giving${index}`} className="w-full flex items-center justify-between mb-1">
                        <div className="text-stone-400 text-xs flex items-center">
                          <CloseIcon className="w-4 h-4 text-stone-400 mr-2 cursor-pointer" onClick={() => handleRemoveProduct(item)} />
                          <p className="text-stone-400 text-xs">{item.name}</p>
                        </div>
                        <p className="text-xs text-DarkerBlue">
                          {ShowCurrencies2(selectedCurrencyStore, item.quantity * item[selectedCurrencyStore.toLowerCase()], false)}
                        </p>
                      </div>
                    );
                  }
                })}
              </div>
              {selectedGivingProducts.products.length > 2 && <div className="flex border-b border-stone-200 pb-2">
                <span className="text-LightBlue text-xs flex items-center cursor-pointer mt-1" onClick={() => setShowMoreItems(!showMoreItems)}>
                  {showMoreItems
                    ? <>Show less items <ChevronDownIcon className={`w-3 h-3 text-stone-500 ml-2`} /></>
                    : <>View more items <ChevronUpIcon className={`w-3 h-3 text-stone-500 ml-2`} /></>
                  }
                </span>
              </div>}
              <div className="flex justify-between items-center border-t border-stone-300 py-1 w-full">
                <p className="text-stone-500 text-xs">Total Amount</p>
                <p className="text-sm font-bold text-DarkerBlue">{ShowCurrencies2(selectedCurrencyStore, selectedGivingProducts.total, false)}</p>
              </div>
            </div>
            <div className="flex items-center mt-1 w-full">
              <CustomButton
                onClick={() => handleBack()}
                title={'Back'}
                type="button"
                className={`w-auto !bg-[#00a3da] mr-2`} />
              <CustomButton
                title={
                  <>
                    {`Give `}
                    {ShowCurrencies2(selectedCurrencyStore, selectedGivingProducts.total, false)}
                  </>
                }
                link={'/checkout'}
                className={`${lang === 'ar' ? '' : 'font-["Montserrat"]'} font-bold w-full`}
              />
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </Layout>
  );
};

export default MATWAppealLandingPage;