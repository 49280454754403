import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { projectNoorFaqs } from './faqs';
import Layout from '../../components/general/layout';
import Accordion from '../../components/general/accordion';
import ProductBox from '../../components/general/productBox';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import CrisisWidget from '../crisis-and-emergencies/components/crisisWidget';
import ZakatWidget from '../zakat/zakatWidget';
import { useKeenSlider } from 'keen-slider/react'
import Noor1 from '../../images/sadaqah/noor1.jpg'
import Noor2 from '../../images/sadaqah/noor2.jpg'
import Noor3 from '../../images/sadaqah/noor3.jpg'
import Noor4 from '../../images/sadaqah/noor4.jpg'
import Noor5 from '../../images/sadaqah/noor5.jpg'
import banner from '../../images/sadaqah/Project-Noor-Generic-Hero-Banner-Desktop.jpg';
import bannerMobile from '../../images/sadaqah/Project-Noor-Generic-Hero-Banner-Mobile.jpg';
import { useNavigate, useParams } from 'react-router-dom';
import { getProducts } from '../../store/products';
import { OurPromiseSection } from '../../components/general';
import { generateLinkWithLang } from '../../utils/linkGenerator';
const images = [
    Noor1,
    Noor2,
    Noor3,
    Noor4,
    Noor5,
];

const ProjectNoorUAT = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const products = useSelector((state) => getProducts(state, 'Sadaqah Jariyah', 'health'));
    const [selectedCurrency, setSelectedCurrency] = useState();
    const [masjidProducts, setMasjidProducts] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
    const selectedcountryCode = useSelector(selectSelectedCountryCode);
    const seo = SeoData[selectedcountryCode];
    const [isLoaded, setIsLoaded] = useState(false);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [currentSlideImages, setCurrentSlideImages] = useState(0);
    const [sliderRefImages, instanceRefImages] = useKeenSlider({
        mode: "free-snap",
        loop: true,
        slideChanged(slider) {
            setCurrentSlideImages(slider.track.details.rel)
        },
        created() {
            setLoaded(true)
        },
        slides: {
            origin: "auto",
            perView: 1,
            spacing: 8,

        },
    },
        [
            slider => {
                let timeout;
                let mouseOver = false;
                function clearNextTimeout() {
                    clearTimeout(timeout);
                }
                function nextTimeout() {
                    clearTimeout(timeout);
                    if (mouseOver) return;
                    timeout = setTimeout(() => {
                        slider.next();
                    }, 2000);
                }
                slider.on('created', () => {
                    slider.container.addEventListener('mouseover', () => {
                        mouseOver = true;
                        clearNextTimeout();
                    });
                    slider.container.addEventListener('mouseout', () => {
                        mouseOver = false;
                        nextTimeout();
                    });
                    nextTimeout();
                });
                slider.on('dragStarted', clearNextTimeout);
                slider.on('animationEnded', nextTimeout);
                slider.on('updated', nextTimeout);
            },
        ]

    )
    const { lang } = useParams(); // Get the language from the URL
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */

    const getAllProducts = () => {
        setIsLoaded(true);
        let tempProductsList = [];
        let p = products.subCategory.products;
        p.forEach(item => {
            if (item.creator === 'HLT-DIS-ERI-2023-01-062') tempProductsList.push(item);
            if (item.creator === 'HLT-DIS-ERv-2023-01-063') tempProductsList.push(item);
            if (item.creator === 'HLT-DIS-ERX-2023-01-064') tempProductsList.push(item);
        });
        setMasjidProducts(tempProductsList);
    };
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
    }, [selectedCurrencyStore])
    useEffect(() => {

        products && Object.keys(products).length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])
    /* -------------------------------------------------------------------------- */
    //init

    return (
        <Layout className='bg-white' hideFooter>
            {seo &&
                <Helmet>
                    <title>{`${seo['sadaqahJariyah']['health']['title']}`}</title>
                    <meta name="title" content={`${seo['sadaqahJariyah']['health']['title']}`} />
                    <meta name="description" content={`${seo['sadaqahJariyah']['health']['description']}`} />
                </Helmet>
            }
            {/* /* --------------------------------- Banner ---------------------------------  */}
            <section className="flex justify-center" >
                <img src={banner} alt="banner" className='max-[500px]:hidden w-full' />
                <img src={bannerMobile} alt="banner" className='min-[501px]:hidden w-full' />
            </section>
            <section className='bg-[#DFEEF2]'>
                <section className='max-w-[1440px] mx-auto'>
                    <div className='text-[#093686] pt-5 leading-[45px] text-center text-[35px] font-bold'>Make a Donation</div>
                    <div className='w-full hidden lg:mx-auto px-4 xl:px-10 py-4 md:py-6 sm:flex justify-center items-center'>
                        <CrisisWidget className={'bg-[#DFEEF2] px-6 h-auto pt-10'}
                            defaultProduct={{
                                creator: "MKD-GEN-MPN-2023-01-124",
                                label: "Project Noor",
                                value: 109 // its id
                            }}
                        />
                    </div>
                    <div className='w-full sm:hidden lg:mx-auto px-4 xl:px-10 py-4 md:py-6 flex justify-center items-center'>
                        <ZakatWidget hasSuggestPrice defaultProduct={{
                            creator: "MKD-GEN-MPN-2023-01-124",
                            label: "Project Noor",
                            value: 109 // its id
                        }} />
                    </div>
                </section>
            </section>
            <OurPromiseSection />
            <main className='max-w-[1440px] mx-auto'>
                <section className='xl:px-20 mb-8 sm:mb-16'>
                    <div className='relative overflow-hidden flex flex-wrap'>
                        <div className='order-2 z-10 sm:order-1 bg-[#F60362] flex flex-col items-center md:items-start justify-center w-full md:max-w-[365px] min-h-[300px]
                        md:min-h-[300px] lg:min-h-[305px] md:top-1/2 md:-translate-y-1/2
                        right-16 bg-no-repeat bg-cover bg-center md:rounded-2xl md:absolute  ' >
                            <div className='leading-[50px]  w-full text-[24px] sm:text-[50px] md:text-[30px] lg:text-[28px] px-8 text-white text-center font-gotcha'>Project Noor</div>
                            <p className='text-[18px] sm:text-[18px] font-brandingMedium leading-6 mt-3 px-10 text-white text-center'>
                                In a world full of darkness, your Sadaqah has the power to bring light by restoring sight for those suffering from avoidable blindness.
                            </p>
                        </div>
                        <div className='hidden sm:block absolute w-full h-full bg-[#00000085]' />
                        <div className=" basis-full max-[380px]:min-h-[209px] min-[381px]:min-h-[215px] min-[410px]:min-h-[230px] sm:h-[300px] md:h-[600px] lg:h-[720px]">
                            <iframe className='video pointer-events-none'
                                width={'100%'}
                                height={'100%'}
                                title={'youtube'}
                                frameBorder={0}
                                sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                                src={`https://www.youtube.com/embed/Z3DtUoz0scc?controls=0&autoplay=1&loop=1&mute=1&playlist=Z3DtUoz0scc`}
                            >
                            </iframe>
                        </div>

                    </div>
                </section>
                <section className='xl:px-10'>
                    <div className='flex justify-center'>
                        <div className='text-center font-bold text-[#093686] leading-[30px] sm:leading-[55px] text-2xl sm:text-6xl px-4 w-full sm:w-10/12'>
                            Your<span className='text-[#F60362] ml-2'>
                                Sadaqah Jariyah</span>,could change lives.
                        </div>
                    </div>
                    <div className='px-4 md:px-6 mt-2 sm:mt-10  font- leading-5'>
                        <div className='text-[#78716C] leading-6 text-[18px] text-left px-4 font-brandingMedium'>
                            What would you do if you couldn’t look upon the face of your loved ones or read the beautiful words of the Quran? Could you live in a world of darkness?
                            <br /><br />
                            Imagine being blind purely because of your circumstances. A life lived in poverty for those suffering from avoidable blindness means a life lived in darkness. It’s unthinkable, yet there are millions of people around the world who suffer from this.
                            <br /><br />
                            9 out of 10 people who are visually impaired are suffering from loss of sight that could be treated  and prevented. In developing countries, the human cost behind this impacts everything from access to education, livelihoods and more. Most of those suffering do not have access to life’s most basic necessities let alone quality health and eye care.
                            <br /><br />
                            <span className='text-[#F60362] font-bold'>This is a huge injustice. One that we can work together to change.</span>
                            <br /><br />
                            <span className='text-[#F60362] font-bold'>Save sight with your Sadaqah.</span>
                        </div>


                        <div className="flex  flex-row justify-center flex-wrap col-span-12 mt-10 mb-6 sm:mb-10">
                            {masjidProducts && masjidProducts.map((item, index) => {
                                return (
                                    <div key={`makedonation${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                                        <ProductBox product={item} isStaticProduct={item.isStaticProduct || false} currency={selectedCurrency ? selectedCurrency : 'AUD'} />
                                    </div>
                                );
                            })}
                        </div>

                    </div>
                </section>



                <section className='xl:px-20  py-8 bg-[#F1F1F1]'>

                    <div >
                        <div className='flex justify-center pt-4'>
                            <div className='text-left sm:text-center font-bold text-[#253B7E] text-2xl sm:text-6xl px-4 w-full sm:w-8/12'>
                                MATW & Project Noor
                            </div>
                        </div>
                        <div className='px-4 md:px-28 text-[18px] mt-8 leading-5'>
                            <p className='text-[#78716C] leading-[20px] sm:leading-[25px] font-brandingMedium text-[18px] '>
                                Our Muslims Around The World team has been hard at work in Bangladesh, Indonesia and Pakistan restoring sights for vulnerable people
                                living in impoverished communities for years.
                                <br />
                                <br />
                                We focus on setting up makeshift medical camps in remote communities that perform eye health checks, treatment and cataract surgeries.
                                <br />
                                <br />
                                To date, MATW has performed 6,157 cataract surgeries alone, restoring sight through the generosity and Sadaqah of our donors.
                            </p>
                            <p className="basis-full text-lg md:text-xl text-[#F60362] my-2 md:my-4">
                                Give this beautiful Sadaqah Jariyah today
                            </p>
                            <div className="basis-full md:basis-2/3 flex justify-center flex-wrap my-2 md:my-4">
                                <button
                                    className='text-white flex items-center justify-center mt-4 text-lg md:text-2xl transition-all duration-200 bg-[#f60362] hover:bg-[#c3004c] h-[52px] px-6 sm:px-8 py-4 rounded-md'
                                    onClick={() => navigate(generateLinkWithLang('/fundraising', lang))}>
                                    Donate Now
                                </button>
                            </div>
                        </div>
                    </div>



                </section>



                {/* /* --------------------------------- Images --------------------------------- */}
                <section className='bg-[#F8F8F8]  py-10'>
                    <div className="md:container md:mx-auto flex justify-center flex-wrap  mt-12">

                        <div className="w-full  flex-wrap justify-center items-center px-6 sm:px-0 relative">
                            <div ref={sliderRefImages} className="keen-slider">
                                {images.map((item, index) => {

                                    return <div key={`carousel_images${index}`} className={`keen-slider__slide basis-full  flex justify-center rounded-xl sm:rounded-none`}>
                                        {/* <div className={`bg-[url('../src/images/zakat/zakat-alfitr${index + 1}.jpg')] bg-center rounded bg-cover h-[300px] w-full`} /> */}
                                        <img src={item} alt={`carousel_images${index}`} className=' bg-top object-cover w-full object-top' />
                                    </div>
                                })}
                            </div>

                        </div>
                        <div className="w-full  flex justify-center">
                            {loaded && instanceRefImages.current && (
                                <div className="dots flex pt-3 pb-6">
                                    {[
                                        ...Array(instanceRefImages.current.track.details.slides.length).keys(),
                                    ].map((idx) => {
                                        return (
                                            <button
                                                key={idx}
                                                onClick={() => {
                                                    instanceRefImages.current?.moveToIdx(idx)
                                                }}
                                                className={"dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer" + (currentSlideImages === idx ? " active bg-[#253A7E]" : " bg-gray-300")}
                                            ></button>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </section>
                {/* /* -------------------------------------------------------------------------- */}

                {/* -------------------------------------FAQS--------------------------------- */}
                <section className='px-4 xl:px-20 mt-10 bg-white'>
                    <h1 className='text-[#253B7E] text-center mb-8 text-[30px] sm:text-[60px] font-bold'>
                        Project Noor donations <span className='text-[#F60362]'> FAQ’s</span>
                    </h1>

                    <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
                        {projectNoorFaqs.map((item, index) => {
                            return index > 2 && (
                                <div className='bg-white rounded-md p-4 mt-2 w-full shadow-md' key={`faq${index}`}>
                                    <Accordion key={`keyallfaq${index}`} shadow title={item.question} index={index} level={1} noBorder={true}>
                                        {item.answer}
                                    </Accordion>
                                </div>
                            );
                        })}
                    </div>
                </section>
            </main>
        </Layout>
    )
}

export default ProjectNoorUAT;
