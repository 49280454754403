import { useNavigate, useParams } from 'react-router-dom';
import useHandleAddProduct from '../../utils/handleAddProduct';
import { useEffect, useState } from 'react';
import CustomButton from './button';
import { generateLinkWithLang } from '../../utils/linkGenerator';

const SadaqahJariyahUk = props => {
  /* -------------------------------- Variables ------------------------------- */
  const { product, currency } = props;
  const navigate = useNavigate();
  const [addProduct] = useHandleAddProduct();
  const [customStyle, setCustomStyle] = useState({});
  const { lang } = useParams(); // Get the language from the URL

  const dynamicStyleHandler = name => props.customStyle && customStyle[name] && customStyle[name];

  useEffect(() => {
    setCustomStyle(prevState => {
      return {
        ...prevState,
        ...props.customStyle,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /* -------------------------------------------------------------------------- */
  return (
    <div className="border-[#00A3DA] border-b-[12px]">
      <div
        className={`hidden sm:block bg-[#01A0D8] text-sm sm:text-2xl- sm:text-[18px] font-brandingMedium py-4 text-white  px-6 sm:px-[100px] xl:px-16 text-center ${dynamicStyleHandler(
          'subTitle',
        )}`}
      >
        {product.subTitle}
      </div>
      <div className={`${props.ramadanStyle ? 'hidden' : ''} sm:hidden bg-[#01A0D8] text-sm sm:text-2xl- sm:text-[18px] font-brandingMedium py-2 sm:py-4 text-white px-0 sm:px-[100px] sm:text-center`}>
        {product.subTitleCopy}
      </div>

      <div
        className={`px-8 sm:px-20 bg-white min-h-[350px] rounded-md
            shadow-[0px_10px_25px_0px_rgba(37,42,52,0.08)] gap-x-5 flex flex-wrap md:flex-nowrap justify-center items-center ${dynamicStyleHandler(
          'body',
        )}`}
      >
        <div className={`basis-full md:flex-1  md:pr-3 lg:pr-20 ${dynamicStyleHandler('content')}`}>
          <div
            className={`text-base my-1 sm:my-2 text-center sm:text-left lg:text-[18px] font-bold leading-9 sm:leading-10 text-[#093484] ${dynamicStyleHandler(
              'name',
            )}`}
          >
            {product.name}
          </div>
          <div
            className={`mb-4 text-sm text-center sm:text-left lg:text-[20px]- lg:text-[14px] xl:text-2xl- text-[#78716C] font-brandingMedium- font-['Montserrat'] font-medium ${dynamicStyleHandler('description')}`}
          >
            {product.description}
          </div>
          {props.anyAmount && <div className="text-center md:text-left md:text-[18px] text-[#F60362] uppercase font-brandingBold">$ any amount</div>}
          <div className={`md:flex flex-wrap mt-2 justify-between ${dynamicStyleHandler('desktopButtonGroup')}`}>
            <CustomButton
              onClick={() => { addProduct({product: product, currency: currency}) }}
              title='Donate Now'
              className={`${dynamicStyleHandler('button')} sm:w-full w-[150px] md:w-full xl:w-[220px] mb-5`} />
            <CustomButton
              onClick={() => { navigate(generateLinkWithLang(product.navigationLink, lang)) }}
              title='View All Appeals'
              className={`!bg-[#093484] ${dynamicStyleHandler('button')} sm:w-full w-[150px] md:w-full xl:w-[220px] mb-5`} />
          </div>
        </div>
        <div className={`basis-full md:flex-1 md:flex md:justify-left ${dynamicStyleHandler('photoFrame')}`}>
          <img className={`rounded-lg ${dynamicStyleHandler('photo')}`} alt="" src={product.image_link} />
          <div className={`md:hidden mt-5 ${dynamicStyleHandler('mobileButtonGroup')}`}>
            <CustomButton
              onClick={() => { addProduct({product: product, currency:currency}) }}
              title='Donate Now'
              className={`${dynamicStyleHandler('button')} sm:w-full md:w-full lg:w-[220px] mb-5`} />
            <CustomButton
              onClick={() => { navigate(generateLinkWithLang(product.navigationLink, lang)) }}
              title='View All Appeals'
              className={`!bg-[#093484] ${dynamicStyleHandler('button')} sm:w-full md:w-full lg:w-[220px] mb-5`} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SadaqahJariyahUk;
