import getSymbolFromCurrency from 'currency-symbol-map';
const ShowCurrencies = (currency,_price,showAbbr = true) => {
    let tempCurrency = currency && currency.toUpperCase();
    // console.log("showcurrencies",currency,_price,tempCurrency);
  let price = Number(_price);
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  };
  if(price && (Number(price) % 1 !== 0)) {
    price = Number(price).toLocaleString('en', options);
  }
//   if(price && (Number(price) % 1 !== 0)) {
//     price = Number(price).toFixed(2);
//   }
  switch (tempCurrency) {
      case 'USD':
          return <div>${price ? price.toLocaleString() : 0}{showAbbr && <small className='ml-0.5 md:ml-1 mt-0.5'>{tempCurrency}</small>}</div>
      case 'AUD':
          return <div>${price ? price.toLocaleString() : 0}{showAbbr && <small className='ml-0.5 md:ml-1 mt-0.5'>{tempCurrency}</small>}</div>
      case 'CAD':
          return <div>${price ? price.toLocaleString() : 0}{showAbbr && <small className='ml-0.5 md:ml-1 mt-0.5'>{tempCurrency}</small>}</div>
      case 'GBP':
          return <div>{getSymbolFromCurrency(tempCurrency.toLowerCase())}{price ? price.toLocaleString() : 0}{showAbbr && <small className='ml-0.5 md:ml-1 mt-0.5'>{tempCurrency}</small>}</div>
      case 'EUR':
          return <div>{getSymbolFromCurrency(tempCurrency.toLowerCase())}{price ? price.toLocaleString() : 0}{showAbbr && <small className='ml-0.5 md:ml-1 mt-0.5'>{tempCurrency}</small>}</div>
      case 'SGD':
          return <div>${price ? price.toLocaleString() : 0}{showAbbr && <small className='ml-0.5 md:ml-1 mt-0.5'>{tempCurrency}</small>}</div>
      case 'MYR':
          return <div>{price ? price.toLocaleString() : 0}{showAbbr && <small className='ml-0.5 md:ml-1 mt-0.5'>{tempCurrency}</small>}</div>
      case 'IDR':
          return <div><small className='mr-0.5'>Rp</small> {price ? price.toLocaleString() : 0}{showAbbr && <small className='ml-0.5 md:ml-1 mt-0.5'>{tempCurrency}</small>}</div>
      case 'AED':
          return <div><small className='mr-0.5'>Dh</small> {price ? price.toLocaleString() : 0}{showAbbr && <small className='ml-0.5 md:ml-1 mt-0.5'>{tempCurrency}</small>}</div>
      default:
          return <div>${price ? price.toLocaleString() : 0}</div>
  }
};

export default ShowCurrencies;