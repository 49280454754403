import { useEffect, useState } from 'react';
import ProductBox from '../../components/general/productBox';
import Layout from '../../components/general/layout';
import WidgetHome from '../../components/widgets/widgetHome';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../store/user';
import eventIftarBanner from '../../images/events/iftar-banner.jpg';
import eventAbdulBanner from '../../images/events/event-banner-abdulwahab.jpg';
import eventBelalBanner from '../../images/events/event-banner-belal-assad.jpg';
import eventShaunBanner from '../../images/events/event-banner-shaun.jpg';
import { selectProducts } from '../../store/products';

const EventLandingPage = ({ speaker, type = 'iftar' }) => {
  /* ----------------------------- Start variables ---------------------------- */
  const [muftimentProducts, setMuftimentProducts] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const products = useSelector(state => selectProducts(state));
  const [isLoaded, setIsLoaded] = useState(false);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  // const [pageType, setPageType] = useState(type)
  // const banner = type === 'iftar' ? eventIftarBanner : type === 'abdulwahab' ? eventAbdulBanner : 'https://cdn.matwproject.org/images/events/large-MATW_Sh.Ali+Hammuda+-+Australian+Tour_2024_EVENT_WebPledgeForm_WebBanner_1920x300+(1).jpg'
  const banners = [
    {
      type: 'iftar',
      banner: eventIftarBanner,
      alt: 'Iftar'
    },
    {
      type: 'abdulwahab',
      banner: eventAbdulBanner,
      alt: 'AbdulWahab'
    },
    {
      type: 'belal',
      banner: eventBelalBanner,
      alt: 'Belal Assad'
    },
    {
      type: 'shaun',
      banner: eventShaunBanner,
      alt: 'Shaun King'
    },
    {
      type: 'alihamud',
      banner: 'https://cdn.matwproject.org/images/events/large-MATW_Sh.Ali+Hammuda+-+Australian+Tour_2024_EVENT_WebPledgeForm_WebBanner_1920x300+(1).jpg',
      alt: 'Alihamud'
    },
    {
      type: 'event',
      banner: 'https://cdn.matwproject.org/images/events/large-MATW_Sh.Ali+Hammuda+-+Australian+Tour_2024_EVENT_WebPledgeForm_WebBanner_1920x300+(1).jpg',
      alt: 'Event'
    },
  ];
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    let tempProductsList = [];
    let tempShaunBelalProductsList = [];

    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          if (inner_item_product.creator === 'RM24-ZM-013') {
            if(type === 'belal' || type === 'shaun') {
              tempShaunBelalProductsList[8] = inner_item_product;
            } else {
              tempProductsList[0] = inner_item_product;
            }
          }
          if (inner_item_product.creator === 'RM24-015') {
            tempProductsList[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'RM24-OP-014') {
            tempProductsList[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'TXT-CD-EV-UK-003') {
            tempProductsList[3] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2023-01-102') {
            tempProductsList[4] = inner_item_product;
          }
          if (inner_item_product.creator === 'WWC-CON-BBW-2023-01-029') {
            if(type === 'belal' || type === 'shaun') {
              tempShaunBelalProductsList[5] = inner_item_product;
            } else {
              tempProductsList[5] = inner_item_product;
            }
          }
          if (inner_item_product.creator === 'WWC-CON-PBW-2023-01-030') {
            tempProductsList[6] = inner_item_product;
          }
          if (inner_item_product.creator === 'WWC-CON-ABW-2023-01-028') {
            tempProductsList[7] = inner_item_product;
          }
          if (inner_item_product.creator === 'WWC-CON-H2W-2023-01-025') {
            tempProductsList[8] = inner_item_product;
          }
          if (inner_item_product.creator === 'MSJ-WA-SMJ-2023-01') {
            if(type === 'belal' || type === 'shaun') {
              tempShaunBelalProductsList[7] = inner_item_product;
            } else {
              tempProductsList[9] = inner_item_product;
            }
          }
          if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') {
            tempProductsList[10] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2023-01-200') {
            tempProductsList[11] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2023-01-203') {
            tempProductsList[12] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2023-01-206') {
            tempProductsList[13] = inner_item_product;
          }
          if (inner_item_product.creator === 'EVT-EMR-PAL-2024-01' && type === 'abdulwahab') {
            tempProductsList[14] = inner_item_product;
          }
          if (inner_item_product.creator === 'EVT-EMR-PAL-2024-02' && type === 'abdulwahab') {
            tempProductsList[15] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-LEB-24-000' && (type === 'belal' || type === 'shaun')) {
            tempShaunBelalProductsList[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-WNT-FND-2023-04' && (type === 'belal' || type === 'shaun')) {
            tempShaunBelalProductsList[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01-01' && (type === 'belal' || type === 'shaun')) {
            tempShaunBelalProductsList[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2024-4-208' && (type === 'belal' || type === 'shaun')) {
            tempShaunBelalProductsList[3] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-317' && (type === 'belal' || type === 'shaun')) {
            tempShaunBelalProductsList[4] = inner_item_product;
          }
          if (inner_item_product.creator === 'WWC-CON-ABW-2023-02-028' && (type === 'belal' || type === 'shaun')) {
            tempShaunBelalProductsList[6] = inner_item_product;
          }
          if (inner_item_product.creator === 'MKD-MN-001' && (type === 'belal' || type === 'shaun')) {
            tempShaunBelalProductsList[9] = inner_item_product;
          }
          if (inner_item_product.creator === 'FST-DEL-F150-2023-01-010' && (type === 'belal' || type === 'shaun')) {
            tempShaunBelalProductsList[10] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2023-01-204' && (type === 'belal' || type === 'shaun')) {
            tempShaunBelalProductsList[11] = inner_item_product;
          }
          if (inner_item_product.creator === 'FWD-DIS-BMM-2023-01-049' && (type === 'belal' || type === 'shaun')) {
            tempShaunBelalProductsList[12] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-PAL-MASU-005' && (type === 'belal' || type === 'shaun')) {
            tempShaunBelalProductsList[13] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-PAL-MASU-009' && (type === 'belal' || type === 'shaun')) {
            tempShaunBelalProductsList[14] = inner_item_product;
          }
          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    tempShaunBelalProductsList.length && tempProductsList.unshift(...tempShaunBelalProductsList)
    // tempProductsList.forEach(e => console.log(e.creator));
    tempProductsList = tempProductsList.filter(n => n);
    // console.log("products=",type,tempProductsList)
    setMuftimentProducts(tempProductsList);
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  useEffect(() => {
    products.length && !isLoaded && getAllProducts();
    // console.log(isIftar);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */
  //init

  return (
    <Layout>
      <div>
        {
          <img
            src={banners.filter(item => item.type === type).map(item => item.banner) || banners[banners.length - 1].banner}
            alt={banners.filter(item => item.type === type).map(item => item.alt) || banners[banners.length - 1].alt}
            className="w-full"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = '/images/general/logo-replace.png';
              currentTarget.className = 'max-h-[150px] mx-auto';
            }}
          />
        }
      </div>
      <WidgetHome selectedCurrency={e => setSelectedCurrency(e.value)} />
      <div className="md:container md:mx-auto mt-5">
        <div className="grid grid-cols-12 px-3- md:px-4-">
          <div className="col-span-12 text-center text-5xl md:text-6xl text-[#253b7e] flex justify-center items-center py-2- md:py-8- font-['Gotcha'] h-[125px]">
            Plant Your Sadaqah
          </div>
          <div className="col-start-1 col-span-12 rounded-md">
            <div className="flex flex-row justify-center flex-wrap">
              {muftimentProducts.map((item, index) => {
                return (
                  <div key={`products.products${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                    <ProductBox product={item} currency={selectedCurrency ? selectedCurrency : 'AUD'} />
                  </div>
                );
              })}
            </div>
            <div className={`col-span-12 rounded-xl mt-6 lg:min-h-[200px] bg-white p-6 lg:p-12 text-center shadow-lg`}>
              <h1 className="text-[#00a3da] text-4xl mb-4">“Charity does not decrease wealth.” - The Prophet Mohammed ﷺ [Muslim]</h1>
              <p className="text-[#777] text-xl mb-4">
                Never underestimate the power of your Sadaqah or Zakat. It can change lives, uplifting the most vulnerable people in the world. Donate
                now.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EventLandingPage;
