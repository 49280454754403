import { useEffect, useState } from 'react';
import Layout from '../../components/general/layout';
import { Link, useNavigate, useParams } from 'react-router-dom';
import WidgetHome from '../../components/widgets/widgetHome';
import newGenerationImg from '../../images/general/newgenerationheader.png';
import clickerImg from '../../images/general/clickhereimpact.png';
import impactHeaderImg from '../../images/general/MATW-Home-Banner-TheMATWImpact-Heading.png';
import touchinImg from '../../images/home/thouchin.png';
import aliFriendImg from '../../images/ali-banat/ali-and-friends.png';
import CountUp from 'react-countup';
import ProductBox from '../../components/general/productBox';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import PalestineBannerImg from '../../images/home/palestineBannerImg.jpg';
import { selectProducts } from '../../store/products';
import useHandleAddProduct from '../../utils/handleAddProduct';
import ContactForm from '../home/ContactForm';
import CarouselSection from '../home/carouselSection';
import { generateLinkWithLang } from '../../utils/linkGenerator';

const GlobalPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const navigate = useNavigate();
  const [addProduct] = useHandleAddProduct();
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  // const selectedUserProductsInStore = useSelector(selectUserProductsInStore);
  const [isLoaded, setIsLoaded] = useState(false);
  const [orphanProducts, setOrphanProducts] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [healthProducts, setHealthProducts] = useState([0, 0, 0, 0, 0, 0]);
  const [masjidProducts, setMasjidProducts] = useState([0, 0, 0, 0, 0, 0]);
  const [mostNeededProducts, setMostNeededProducts] = useState([0, 0, 0, 0, 0, 0]);
  const [topProducts, setTopProducts] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [palestineProducts, setPalestineProducts] = useState([]);
  const [palestineMostNeedProduct, setPalestineMostNeedProduct] = useState();
  const [selectedCurrency, setSelectedCurrency] = useState();
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  const products = useSelector((state) => selectProducts(state));
  const { lang } = useParams(); // Get the language from the URL
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    let tempPalestineProducts = [];
    let tempTopProducts = [];
    let tempMostNeededProducts = [];
    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          if (inner_item.name === 'Support An Orphan') {
            setOrphanProducts(inner_item.products); // Orphans
          };
          if (inner_item.name === 'Health') {
            setHealthProducts(inner_item.products); // Health
          };
          if (inner_item.name === 'Build A Masjid') {
            setMasjidProducts(inner_item.products); // Build A Masjid
          };
          if (inner_item.name === 'Palestine') {
            if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') { // Palestine Appeal
              inner_item_product = Object.assign({}, inner_item_product, {
                image_link: '/images/landings/crisis/palestine/Palestine Appeal.jpg'
              })
              tempTopProducts[0] = inner_item_product;
              tempPalestineProducts[0] = inner_item_product;
              tempMostNeededProducts[0] = inner_item_product;
              setPalestineMostNeedProduct(inner_item_product);
            }
            if (inner_item_product.creator === 'EMR-DIS-MDPL-2023-01-102') tempPalestineProducts[1] = inner_item_product; // Palestine Emergency Medical Kit
            if (inner_item_product.creator === 'EMR-DIS-BFPL-2023-01-102') tempPalestineProducts[2] = inner_item_product; // Palestine Essential Family Food Pack
            if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01-01') { // Palestine Medical Relief
              tempPalestineProducts[3] = inner_item_product;
              tempMostNeededProducts[1] = inner_item_product;
            }
            if (inner_item_product.creator === 'EMR-DIS-PSE-2023-02') tempMostNeededProducts[4] = inner_item_product; // Food Pack for Palestine
          };
          if (inner_item.name === 'Make A Donation') {
            if (inner_item_product.creator === 'MKD-MN-001') tempTopProducts[4] = inner_item_product; // Where Most Needed
            if (inner_item_product.creator === 'MKD-GEN-MOR-2023-01-119') tempTopProducts[6] = inner_item_product; // Orphans Around The World
          };
          if (inner_item_product.creator === 'RM24-ZM-013') tempTopProducts[5] = inner_item_product; // Zakat Al Mal
          // if (inner_item.name === 'Give Zakat') {
          // };
          if (inner_item.name === 'Build A Masjid') {
            if (inner_item_product.creator === 'GD-MP015') tempTopProducts[7] = inner_item_product; // General Masjid Donations
            if (inner_item_product.creator === 'MSJ-CON-SMJ-2023-01-011') tempTopProducts[8] = inner_item_product; // Small Masjid
          };
          if (inner_item.name === 'Water Aid') {
            if (inner_item_product.creator === 'WWC-CON-PBW-2023-01-030') tempTopProducts[9] = inner_item_product; // Pakistan Basic Water Well
            if (inner_item_product.creator === 'WWC-CON-L1W-2023-01-026') tempTopProducts[10] = inner_item_product // L1 Africa Water Well
            if (inner_item_product.creator === 'FWD-DIS-AR-GN-006') tempMostNeededProducts[3] = inner_item_product; // Water For 500 People
            if (inner_item_product.creator === 'WWC-CON-ABW-2023-01-028') tempMostNeededProducts[5] = inner_item_product; // Africa Basic Water Well
            if (inner_item_product.creator === 'WWC-CON-BBW-2023-01-029') tempMostNeededProducts[6] = inner_item_product; // Bangladesh Basic Water Well
            if (inner_item_product.creator === 'FWD-DIS-WYX-2023-01-055') tempMostNeededProducts[8] = inner_item_product; // Water Distribution In Yemen 10K Litres
          };
          if (inner_item.name === 'Orphan Feast') {
            if (inner_item_product.creator === 'FST-DEL-F25-2023-01-008') tempMostNeededProducts[9] = inner_item_product; // Orphan Feast For 25
        };
          if (inner_item.name === 'Caravan') {
            if (inner_item_product.creator === 'EM-SYR-EQ1-02-2023') tempTopProducts[11] = inner_item_product; // Caravan Fundraising
          };
          if (inner_item.name === 'Earthquake Appeal') {
            if (inner_item_product.creator === 'EMR-DIS-AFG-2023-02') tempTopProducts[1] = inner_item_product; // Afghanistan Earthquake Appeal
            if (inner_item_product.creator === 'EMR-MR-WMN-001-2023') tempTopProducts[2] = inner_item_product; // Morocco Appeal
          };
          if (inner_item.name === 'Libya Floods') {
            if (inner_item_product.creator === 'EMR-LBY-WMN-002-2023') tempTopProducts[3] = inner_item_product; // Libya Floods
          };
          if (inner_item.name === 'Food Aid') {
            if (inner_item_product.creator === 'FWD-DIS-AR-GN-002') tempMostNeededProducts[2] = inner_item_product; // Feed 10 Hungry People
            if (inner_item_product.creator === 'FWD-DIS-FPY-2023-01-045') tempMostNeededProducts[7] = inner_item_product; // Food Pack For Yemen
          };
          return inner_item_product;
        });
        return inner_item;
      })
      return item;
    });
    if (window.location.hostname === 'matwproject.org') {
      setPalestineProducts(tempPalestineProducts);
    }
    tempMostNeededProducts = tempMostNeededProducts.filter(n => n)
    tempTopProducts = tempTopProducts.filter(n => n)
    setTopProducts(tempTopProducts);
    setMostNeededProducts(tempMostNeededProducts);
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  useEffect(() => {
    products.length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products])
  useEffect(() => {
    if (window.location.pathname.includes('undefined')) {
      navigate(generateLinkWithLang('/', lang))
    }
    window.scroll(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /* -------------------------------------------------------------------------- */
  return (
    <Layout>
      {seo &&
        <Helmet>
          <title>{`${seo['home']['title']}`}</title>
          <meta name="title" content={`${seo['home']['title']}`} />
          <meta name="description" content={`${seo['home']['description']}`} />
        </Helmet>
      }
      <WidgetHome selectedCurrency={e => setSelectedCurrency(e.value)} />
      <div className="md:container md:mx-auto mt-0 md:mt-2">
        <CarouselSection />
        <div className="grid grid-cols-12 px-4 md:px-2 lg:px-0">
          {/* /* ------------------------------ Palestine Emergency for AUS OnlY otherwise most needed products----------------------------- */}

          {palestineProducts.length > 0 ?
            <>
              <div className="flex flex-row justify-center flex-wrap col-span-12">
                <h1 className="w-full text-center text-6xl text-[#253b7e] py-2 md:pt-8 font-['Gotcha'] mt-6">Palestine Emergency</h1>
                <div className='text-[#A8A29E] font-brandingMedium text-xl col-span-12 px-10 text-center my-6'>
                  More than 7,000 people have been killed in the conflict in Palestine. Over 3,500 are children. Aid agencies are calling this one of the worst catastrophes in history.
                  Help our team on the ground provide urgent food, water, medical kits and fuel to keep hospitals running.
                </div>
                {palestineProducts.map((item, index) => {
                  return <div key={`makedonation${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                    <ProductBox product={item} currency={selectedCurrency ? selectedCurrency : 'AUD'} />
                  </div>
                })}
              </div>
              <div className="flex mt-4 flex-wrap col-span-12 px-2 cursor-pointer"
                // onClick={() => navigate('checkout?id=eyJwcm9kdWN0IjoiRU1SLURJUy1QU0UtMjAyMy0wMSIsImN1cnJlbmN5IjoiVVNEIiwiaWQiOjI1NSwicXVhbnRpdHkiOjEwMCwiY2F0ZWdvcnlfaWQiOjI3fQ==')}
                onClick={() => { addProduct({product: palestineMostNeedProduct, currency: selectedCurrency}) }}
              >
                <div className='basis-full sm:basis-1/2' ><img src={PalestineBannerImg} className='rounded-t sm:rounded-tr-none sm:rounded-l' alt='matw' /></div>
                <div className='px-6 md:px-2 py-4 rounded-b sm:rounded-bl-none sm:rounded-r basis-full sm:basis-1/2 bg-[#00A3DA] flex flex-col justify-center items-center '>
                  <h1 className='mb-0 text-center text-[24px] sm:text-[30px] drop-shadow-md md:text-[35px] lg:text-[45px] xl:text-[60px] text-white'><b className='mr-1'>OUR PROMISE</b>  <span className='font-gotcha text-[32px] md:text-[60px] lg:text-[90px] xl:text-[113px]'>to you</span></h1>
                  <p className='text-center px-2 sm:px-0 lg:px-4 text-white leading-5 lg:text-[22px] lg:leading-7 xl:text-[25px] xl:leading-[35px] font-bold font-brandingMedium mb-4 md  :mb-8'>
                    Since the beginning of our journey  with Ali Banat,
                    transparency has been of the utmost importance to
                    us. It's at the very core of what we do and who we
                    are at MATW. Our promise to you is our 100% donation policy. Every single Dollar, Pound or Euro
                    raised goes straight to our projects in more than 20
                    countries around the world.
                  </p>
                  <button className='bg-[#F60362] text-[18px] md:text-[30px] px-10 py-2 text-white font-bold rounded-[25px] '>Donate now</button>
                </div>
              </div>
            </>
            /* ------------------------------------------------------------------------------------------------- */
            :
            /* ------------------------------ Start Most Needed ----------------------------- */
            <div className="flex flex-row justify-center flex-wrap col-span-12">
              <h1 className="w-full text-center text-6xl text-[#253b7e] py-2 md:py-8 font-['Gotcha'] my-6">Most Needed</h1>
              {mostNeededProducts.map((item, index) => {
                return <div key={`makedonation${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                  <ProductBox product={item} currency={selectedCurrency ? selectedCurrency : 'AUD'} />
                </div>
              })}
            </div>
          }
          {/* /* -------------------------------------------------------------------------- */}

          {/* /* ------------------------------ Start Top Appeals ------------------------- */}
          <div className="flex hidden- flex-row justify-center flex-wrap col-span-12">
            <h1 className="w-full text-center text-6xl text-[#253b7e] py-2 md:py-8 font-['Gotcha'] my-6">Our Top Appeals</h1>
            {topProducts.map((item, index) => {
              return (
                <div key={`makedonation${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                  <ProductBox product={item} currency={selectedCurrency ? selectedCurrency : 'AUD'} />
                </div>
              );
            })}
          </div>
          {/* /* -------------------------------------------------------------------------- */}

          {/* /* ------------------------------ Start Orphan ----------------------------- */}
          <div className="flex flex-row justify-center flex-wrap col-span-12 mt-6">
            <h1 className="w-full text-center text-6xl text-[#253b7e] py-2 md:py-8 font-['Gotcha'] my-6">Help Us Save Orphans!</h1>
            {orphanProducts.map((item, index) => {
              return (
                <div key={`orphanscate${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                  <ProductBox product={item} currency={selectedCurrency} />
                </div>
              );
            })}
          </div>
          {/* /* -------------------------------------------------------------------------- */}

          {/* /* ------------------------------ Start Health ----------------------------- */}
          <div className="flex flex-row justify-center flex-wrap col-span-12 mt-6">
            <h1 className="w-full text-center text-6xl text-[#253b7e] py-2 md:py-8 font-['Gotcha'] my-6">Health</h1>
            {healthProducts.map((item, index) => {
              return (
                <div key={`healthcate${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                  <ProductBox product={item} currency={selectedCurrency} />
                </div>
              );
            })}
          </div>
          {/* /* -------------------------------------------------------------------------- */}

          {/* /* ------------------------------ Start Masjid ----------------------------- */}
          <div className="flex flex-row justify-center flex-wrap col-span-12 mt-6">
            <h1 className="w-full text-center text-6xl text-[#253b7e] py-2 md:py-8 font-['Gotcha'] my-6">Build A Masjid</h1>
            {masjidProducts.map((item, index) => {
              return (
                <div key={`masjidcate${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                  <ProductBox product={item} currency={selectedCurrency} />
                </div>
              );
            })}
          </div>
          {/* /* -------------------------------------------------------------------------- */}
        </div>
      </div>
      <div className="flex flex-col justify-center flex-wrap col-span-12 bg-[url('../src/images/general/slider-banner-our-purpose.jpg')] bg-cover pt-20 pb-44 mt-10 md:h-[453px]">
        <h1 className="col-span-12 text-center text-6xl text-[#093484] py-2 md:py-8 font-['Gotcha']">Our Purpose...</h1>
        <p className="col-span-12 text-center text-3xl text-[#00a3da]">
          To build legacies with a global impact <br />
          <span className="text-[#093484] text-2xl">#legacy #impact</span>
        </p>
      </div>
      <div className="flex flex-col justify-center flex-wrap col-span-12 bg-[url('../src/images/general/cellphonebgpng.png')] bg-no-repeat bg-center xl:bg-cover py-20 -mt-[170px] items-center md:h-[685px]">
        <img src={newGenerationImg} alt="matw" className="mt-16 md:w-[626px] md:h-[109px]" />
        <h1 className="col-span-12 text-center text-6xl text-white py-2 mb-6 md:py-8 font-bold">
          Over
          <br />
          774,000
        </h1>
        <p className="col-span-12 text-center text-3xl text-white">
          supporters on social <br />
          media
          <br />
          <br />
          we are not just another charity!
        </p>
      </div>
      <div className="flex flex-col justify-between flex-wrap col-span-12 bg-[url('../src/images/general/impactbg.jpg')] bg-cover py-6 items-center md:h-[380px] lg:h-[400px]">
        <img src={impactHeaderImg} alt="" className="mt-16- md:w-[439px] md:h-[74px]" />
        <div className="flex flex-wrap w-full md:container md:mx-auto px-3 py-10 justify-center">
          <div className="basis-1/2 md:basis-1/4 xl:basis-1/6 flex flex-col justify-center items-center">
            <CountUp start={0} end={12} duration={5} separator="" decimals={0} decimal="," prefix="$" suffix="M" delay={0}>
              {({ countUpRef }) => (
                <div>
                  <span className="text-[#00a3da] text-3xl md:text-4xl lg:text-6xl" ref={countUpRef} />
                </div>
              )}
            </CountUp>
            <span className="text-md md:text-lg text-center">Donated To Date</span>
          </div>
          <div className="basis-1/2 md:basis-1/4 xl:basis-1/6 flex flex-col justify-center items-center">
            <CountUp start={900} end={950} duration={5} separator="" decimals={0} decimal="," prefix="" suffix="+" delay={0}>
              {({ countUpRef }) => (
                <div>
                  <span className="text-[#00a3da] text-3xl md:text-4xl lg:text-6xl" ref={countUpRef} />
                </div>
              )}
            </CountUp>
            <span className="text-md md:text-lg text-center">Water Wells Built</span>
          </div>
          <div className="basis-1/2 md:basis-1/4 xl:basis-1/6 flex flex-col justify-center items-center">
            <CountUp start={950} end={1000} duration={5} separator="" decimals={0} decimal="," prefix="" suffix="+" delay={0}>
              {({ countUpRef }) => (
                <div>
                  <span className="text-[#00a3da] text-3xl md:text-4xl lg:text-6xl" ref={countUpRef} />
                </div>
              )}
            </CountUp>
            <span className="text-md md:text-lg text-center">Orphans Sponsored</span>
          </div>
          <div className="basis-1/2 md:basis-1/4 xl:basis-1/6 flex flex-col justify-center items-center">
            <CountUp start={0} end={3} duration={5} separator="" decimals={0} decimal="," prefix="" suffix="Milion" delay={0}>
              {({ countUpRef }) => (
                <div>
                  <span className="text-[#00a3da] text-3xl md:text-4xl lg:text-6xl" ref={countUpRef} />
                </div>
              )}
            </CountUp>
            <span className="text-md md:text-lg text-center">Lives Impacted</span>
          </div>
        </div>
        <Link className="" to={'/annual-reports'}>
          <img src={clickerImg} alt="" className="mt-16- md:w-[606px] md:h-[110px]" />
        </Link>
      </div>
      <div className="flex flex-col justify-between flex-wrap col-span-12 bg-[url('../src/images/banners/MATW_Website_Banner_NewSite_100DonationPolicy_3000px-scaled.webp')] bg-cover py-6 items-center min-h-[220px] sm:min-h-[240px] md:min-h-[350px] lg:min-h-[680px]"></div>
      <div className="flex justify-between flex-wrap col-span-12 bg-[url('../src/images/home/calltoactions-.jpg')] bg-cover py-8 items-center md:h-[247px] xl:h-[300px]">
        <div className="md:container md:mx-auto mt-5">
          <div className="flex flex-wrap w-full justify-center items-center">
            <div className="basis-full sm:basis-1/3 md:basis-3/5 px-4 mb-4">
              <h2 className="text-white text-center md:text-left font-['Gotcha'] font-bold text-4xl">
                No Donation is too Little{' '}
                <span className="transition-all duration-300 text-[#f60362] hover:text-[#00a3da] cursor-pointer" onClick={() => window.scroll({ top: 0, left: 0, behavior: 'smooth' })}>
                  Donate Now
                </span>
              </h2>
              <p className="text-white text-xl text-center md:text-left font-['Gotcha']">-Ali Banat "Gifted with Cancer" (1985-2018)</p>
            </div>
            <div className="basis-full sm:basis-1/3 md:basis-1/5 flex px-4 md:px-0 justify-center lg:justify-end">
              <img src={aliFriendImg} alt="matw" className="max-w-full md:w-[153px] md:h-[114px] lg:w-[204px] lg:h-[152px] 2xl:w-[256px] 2xl:h-[190px]" />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between flex-wrap col-span-12 py-8 bg-white">
        <div className="w-full flex flex-col items-center justify-center my-4">
          <div className="rounded-full w-24 h-24 border-2 border-[#595EBC] text-[#595EBC] flex justify-center items-center">
            <svg className="w-12 h-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="currentColor">
              <path d="M43.41.34,2.81,19.08a4.73,4.73,0,0,0,1.87,9.06H21.86V45.32a4.73,4.73,0,0,0,9.06,1.87L49.66,6.59A4.88,4.88,0,0,0,43.41.34Z" />
            </svg>
          </div>
        </div>
        <div className="basis-full md:basis-1/2 overflow-hidden">
          <img src={touchinImg} alt="matw" className="max-w-full w-full- w-[746px] h-[515px] sm:w-[384px] sm:h-[365px] md:w-[512px] md:h-[353px] lg:w-[640px] lg:h-[442px] xl:w-[760px] xl:h-[525px] 2xl:w-[940px] 2xl:h-[650px]" />
        </div>
        <ContactForm />
      </div>
      {/* <Snowfall
        color="white"
        snowflakeCount={40}
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
        }}
      /> */}
    </Layout>
  );
};
export default GlobalPage;
