import React, { useState } from 'react'; // , { useState }
import ProductBox from '../../../components/general/productBox';
import MultiProductsBox from '../../../components/general/multiProductsBox';
import { useKeenSlider } from 'keen-slider/react';
// import Arrow from '../home/components/tab-arrow';
import { LiaAngleLeftSolid, LiaAngleRightSolid } from 'react-icons/lia';
import CustomButton from '../../../components/general/button';
import Arrow from '../../home/components/tab-arrow';

const CountriesCrisisTabContent = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const {
    products,
    currency,
    productsOnMobile,
    // showDots = true,
    showArrows = true,
    showMultiProductsOnRow = true,
    viewAppealsLink,
    isPalestineRefugee = false,
    arabic = false,
    lang = 'en',
  } = props;
  // const productCount = products.length > 1 ? (products.length > 2 ? 2.1 : 2) : 1;
  // const origin = productCount === 1 ? 'center' : 'auto';
  const [loaded, setLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, instanceRef] = useKeenSlider({
    mode: 'snap',
    loop: true,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    // breakpoints: {
    //   '(min-width: 320px)': {
    //     slides: { perView: 1, spacing: 10, origin: 'center' },
    //     // range: { min: 0, max: 2 },
    //   },
    //   '(min-width: 768px)': {
    //     slides: { perView: 2, spacing: 10 },
    //   },
    //   '(min-width: 992px)': {
    //     slides: { perView: 3, spacing: 16, origin: 'center' },
    //   },
    // },
    slides: {
      origin: 'auto',
      perView: 1,
      spacing: 8,
    },
  });
  // console.log("arabic=",arabic)
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <div className="hidden sm:flex w-full flex-wrap justify-center items-start gap-2 md:gap-4 max-w-[1560px] mx-auto">
        {products &&
          products.map((item, index) => (
            <div key={`products__${index}`} className="w-full basis-1/3 lg:basis-1/4 max-w-[300px]">
              <ProductBox
                stateVariableObj={{ productName: item ? item.name : '' }}
                product={item}
                arabic={arabic}
                lang={lang}
                // linkBtnClasses={item.linkBtnClasses ? item.linkBtnClasses : '!font-normal'}
                linkBtnClasses={item ? item.linkBtnClasses : ''}
                isStaticProduct={item ? item.isStaticProduct : false}
                staticLink={item ? item.staticLink : ''}
                staticLinkText={item ? item.staticLinkText : ''}
                currency={currency ? currency : 'AUD'}
                titleClasses={`uppercase`}
                isPalestineRefugee={isPalestineRefugee}
              />
            </div>
          ))}
      </div>
      <div className="w-full sm:hidden flex-wrap justify-center items-center relative">
        <div className="hidden- md:flex ">
          {instanceRef.current && (
            <div
              className={`absolute flex justify-center  items-center translate-y-1/2 w-8 h-8 top-[47%] -left-[16px] z-10 cursor-pointer text-white`}
              onClick={e => e.stopPropagation() || instanceRef.current?.prev()}
            >
              <LiaAngleLeftSolid size={25} />
            </div>
          )}
          {instanceRef.current && (
            <div
              className={`absolute flex justify-center items-center translate-y-1/2 w-8 h-8 top-[47%] -right-[16px] z-10 cursor-pointer text-white`}
              onClick={e => e.stopPropagation() || instanceRef.current?.next()}
            >
              <LiaAngleRightSolid size={25} />
            </div>
          )}
        </div>
        {/* <div
          className="absolute translate-y-1/2 h-full- h-[28px] top-[47%] -left-[16px] z-10"
          onClick={e => e.stopPropagation() || instanceRef.current?.prev()}
        >
          <LiaAngleLeftSolid color="white" size={37} />
        </div>
        <div
          className="absolute translate-y-1/2 h-full- h-[28px] top-[47%] -right-[16px] z-10"
          onClick={e => e.stopPropagation() || instanceRef.current?.next()}
        >
          <LiaAngleRightSolid color="white" size={37} />
        </div> */}
        <div ref={sliderRef} className="keen-slider !w-[94%] min-[1280px]:!w-[94%] mx-auto">
          {products.map((item, index) => {
            return (
              <div key={`active_carousel_${index}`} className="keen-slider__slide basis-full flex justify-center py-4">
                <div className="w-full max-w-[300px]">
                  <ProductBox
                    stateVariableObj={{ productName: item.name }}
                    product={item}
                    arabic={arabic}
                    lang={lang}
                    currency={currency ? currency : 'AUD'}
                    titleClasses={`uppercase`}
                    isPalestineRefugee={isPalestineRefugee}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex bg-white py-3 px-2 rounded-md justify-center items-center mx-auto mt-2 max-w-[300px]">
          {loaded && instanceRef.current && (
            <div className="flex justify-center items-center relative w-full">
              <Arrow left onClick={e => e.stopPropagation() || instanceRef.current?.prev()} disabled={currentSlide === 0} />
              {[...Array(instanceRef.current.track.details.slides.length).keys()].map(idx => {
                return (
                  <button
                    key={`q${idx}`}
                    onClick={() => {
                      instanceRef.current?.moveToIdx(idx);
                    }}
                    className={
                      'md:w-4 md:mx-2 mx-1 md:h-4 h-3  w-3 rounded-full ' +
                      (currentSlide === idx ? ' bg-[#F60362] hover:opacity-60' : 'bg-[#C3C3C3] hover:bg-[#F60362')
                    }
                  ></button>
                );
              })}
              <Arrow
                onClick={e => e.stopPropagation() || instanceRef.current?.next()}
                disabled={currentSlide === instanceRef.current.track.details.slides.length - 1}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default CountriesCrisisTabContent;
