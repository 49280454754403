import { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Skeleton from 'react-loading-skeleton';
import useHandleAddProduct from '../../../utils/handleAddProduct';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ShowCurrencies from '../../../utils/showCurrencies';
import CustomButton from '../../../components/general/button';
import { useNavigate, useParams } from 'react-router-dom';
import IsGTMScriptLoaded from '../../../utils/isGTMScriptLoaded';
import { generateLinkWithLang } from '../../../utils/linkGenerator';

const Top10ProductBox = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const navigate = useNavigate();
    const [addProduct] = useHandleAddProduct();
    const { product, currency, arabic = false, buttonLable, linkBtnClasses, view = 'list' } = props;
    const [isAnyAmount, setIsAnyAmount] = useState(false);
    const { lang } = useParams(); // Get the language from the URL
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const handleCustomProductAmount = (product) => {
        if (
            Number(process.env.REACT_APP_MAKE_DONATION_CATEGORY_ID) === Number(product.category_id) ||
            product.id === 52 ||
            product.id === 69
        ) {
            setIsAnyAmount(true);
        } else {
            // if (Number(product[currency.toLowerCase()]) === 1) {
            let hasQuantity = product.quantity
                ? product.quantity > 1
                    ? true
                    : false
                : false
            // if (Number(product[currency.toLowerCase()]) < 6 && !hasQuantity) {
            if (Number(product.aud) < 2 && !hasQuantity) {
                setIsAnyAmount(true);
            } else {
                setIsAnyAmount(false);
            }
        }
    }

    const handleViewItemDataLayer = (tempProduct, currency) => {
        // console.log("datalayer view item", tempProduct)
        let dataLayerTemp = {
            event: 'view_item',
            ecommerce: {
                currency: currency.toUpperCase(),
                items: [{
                    item_id: tempProduct.creator,
                    item_name: tempProduct.name,
                    item_brand: tempProduct.category || "",
                    item_category: tempProduct.category || "",
                    item_description: tempProduct.description || '',
                    price: tempProduct[currency.toLowerCase()],
                }]
            }
        }
        window.dataLayer = window.dataLayer || [];
        // console.log("datalayer view item=", tempProduct, dataLayerTemp, window.dataLayer)
        IsGTMScriptLoaded() && window.dataLayer.push(dataLayerTemp);

        let obj = {
            content_type: 'product',
            content_id: tempProduct.creator,
            currency: currency,
            value: tempProduct[currency.toLowerCase()],
            price: tempProduct[currency.toLowerCase()],
            quantity: 1,
            content_category: tempProduct.category ? tempProduct.category : 'donation',
            content_name: tempProduct.name,
            description: tempProduct.description,
        }
        // window.ttq = window.ttq || [];
        window.ttq && window.ttq.track('ViewContent', {
            ...obj
        });

    }
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        product && handleCustomProductAmount(product)
        // product && ref.current.clientHeight > 79 && setIsOverflow(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product]);
    /* -------------------------------------------------------------------------- */
    return (
        <>
            {view === 'list'
                ? <div className="basis-full min-[450px]:basis-8/12 md:basis-9/12 lg:basis-10/12 flex md:flex-col items-center rounded-md shadow-md bg-white p-1 md:p-2">
                    <div className="w-full min-[380px]:w-8/12 sm:w-full flex flex-row sm:flex-wrap md:flex-col items-center transition-all duration-200 hover:bg-stone-100-">
                        <div className='w-[40px] min-w-[40px] md:w-full rounded-md sm:flex sm:mr-2 justify-center items-center mr-1 md:mb-2 [&>span]:w-full h-[40px]- md:h-[123px]- overflow-hidden'>
                            {product ? product.image_link
                                ? <LazyLoadImage
                                    alt={product.name}
                                    effect="blur"
                                    width={`100%`}
                                    delayTime={500}
                                    className='rounded cursor-pointer'
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = "/images/general/logo-replace.png";
                                    }}
                                    src={product.image_link} // use normal <img> attributes as props
                                />
                                : <img className='w-full' src="/images/general/logo-replace.png" alt="" />
                                : <Skeleton width={`100%`} height={123} />
                            }
                        </div>
                        <h3 className="text-[#777] md:justify-center leading-4 md:leading-none text-xs min-[450px]:text-sm sm:text-base md:text-sm md:text-center md:min-h-[30px] lg:min-h-[40px] flex items-center md:w-full [&>span]:w-full">
                            {product ? product.name : <Skeleton width={`100%`} height={30} />}
                        </h3>
                    </div>
                    <div className="w-full min-[380px]:w-4/12 md:basis-3/12 lg:w-11/12 flex md:flex-col sm:justify-between items-center justify-end">
                        <h2 className={`text-[#f60362] ${isAnyAmount ? 'text-[9px]' : 'text-xs'} md:text-base text-center font-bold mr-2 sm:mr-0`}>
                            {product
                                ? isAnyAmount
                                    ? 'Any Amount'
                                    : <>{product.quantity
                                        ? ShowCurrencies(currency, product.quantity * product[currency.toLowerCase()], true)
                                        : ShowCurrencies(currency, product[currency.toLowerCase()], true)
                                    }</>
                                : <Skeleton width={`100%`} height={20} />}
                        </h2>
                        <CustomButton
                            onClick={() => { addProduct({product: product, currency: currency}) }}
                            title={buttonLable ? buttonLable : arabic ? 'تبرع' : 'Donate'}
                            className={`${linkBtnClasses ? linkBtnClasses : ''} ${arabic ? 'font-[AlmariMedium]' : ''} !h-8 md:!h-10 !text-[10px] md:!text-sm !px-1 md:!px-2`} />
                    </div>
                </div>
                : <div className="w-full p-1 md:p-2 md:pb-5 rounded-xl shadow-lg bg-white flex flex-col">
                    <div className="overflow-hidden img-hover-zoom rounded-md w-full tooltip [&>span]:!block"
                        onClick={() => navigate(generateLinkWithLang(`/appeals/${product.name.replace(/\s+/g, '-').toLowerCase()}`, lang))}>
                        {product ? product.image_link
                            ? <LazyLoadImage
                                alt={product.alt ? product.alt : product.name}
                                effect="blur"
                                width={`100%`}
                                delayTime={500}
                                afterLoad={() => {
                                    handleViewItemDataLayer(product, currency)
                                }}
                                className='rounded cursor-pointer block'
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = "/images/general/logo-replace.png";
                                }}
                                src={product.image_link} // use normal <img> attributes as props
                            />
                            : <img className='w-full' src="/images/general/logo-replace.png" alt="" />
                            : <Skeleton height={344} />
                        }
                    </div>
                    <p className={`text-[#777] cursor-pointer flex justify-center items-center ${view === 'grid' ? 'text-[10px]' : 'text-xs'} sm:text-base text-center min-h-[26px] sm:min-h-[40px] lg:h-[56px]  my-2 leading-4 md:leading-none uppercase ${arabic ? 'font-[AlmariMedium] font-bold' : 'font-medium'}`}
                        onClick={() => navigate(generateLinkWithLang(`/appeals/${product.name.replace(/\s+/g, '-').toLowerCase()}`, lang))}>
                        {product ? product.name : <Skeleton height={56} />}
                    </p>
                    <div className="grid grid-cols-6 gap-1 2xl:gap-2">
                        {product ? <div className={`text-[#f60362] col-span-6 ${view === 'grid' ? 'text-sm' : 'text-sm'} md:text-lg text-center font-bold ${arabic ? 'font-[AlmariMedium]' : ''}`}>
                            {isAnyAmount
                                ? arabic ? 'أي مبلغ' : 'Any Amount'
                                : <>{product.quantity
                                    ? ShowCurrencies(currency, product.quantity * product[currency.toLowerCase()], true)
                                    : ShowCurrencies(currency, product[currency.toLowerCase()], true)
                                }</>
                            }
                        </div> : <div className='col-span-6'><Skeleton /></div>}
                        <div className='flex justify-center col-span-6'>
                            {product
                                ? <CustomButton
                                    onClick={() => { addProduct({product: product, currency: currency}) }}
                                    title={buttonLable ? buttonLable : arabic ? 'تبرع' : 'Donate'}
                                    className={`${linkBtnClasses ? linkBtnClasses : ''} ${arabic ? 'font-[AlmariMedium]' : ''} !h-6 md:!h-10 !text-[10px] md:!text-sm !px-2`} />
                                : <Skeleton height={43} />
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default Top10ProductBox;