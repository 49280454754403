import { useEffect, useState } from 'react';
import Layout from '../../components/general/layout';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode } from '../../store/user';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import { palestineFamineFaqsFrench, palestineFamineFaqsArabic, palestineFamineFaqs } from './components/faqs';
import { useKeenSlider } from 'keen-slider/react';
import CrisisWidget from './components/crisisWidget';
import VideosSection from '../../components/general/videosSection';
import { selectProducts } from '../../store/products';
import Palestinepreramadan1 from '../../images/landings/palestine/food-aid-gallery-1.jpg';
import Palestinepreramadan2 from '../../images/landings/palestine/food-aid-gallery-2.jpg';
import Palestinepreramadan3 from '../../images/landings/palestine/food-aid-gallery-3.jpg';
import Palestinepreramadan4 from '../../images/landings/palestine/food-aid-gallery-4.jpg';
import Palestinepreramadan5 from '../../images/landings/palestine/food-aid-gallery-5.jpg';
import Palestinepreramadan6 from '../../images/landings/palestine/food-aid-gallery-6.jpg';
import bannerAr from '../../images/landings/palestine/Palestine-Famine-Hero-Banner-Desktop-ar.jpg';
import bannerMobileAr from '../../images/landings/palestine/Palestine-Famine-Hero-Banner-Mobile-ar.jpg';
import bannerFr from '../../images/landings/palestine/Palestine-Famine-Hero-Banner-Desktop-Fr.jpg';
import bannerMobileFr from '../../images/landings/palestine/Palestine-Famine-Hero-Banner-Mobile-Fr.jpg';
import waterAidPhoto from '../../images/landings/palestine/food-and-water-aid-food-aid-baby-milk-for-52-weeks.jpg';
import OurPromiseSection from '../../components/general/ourPromiseSection';
import YoutubeVideoHero from '../../components/general/youtubeVideoHero';
import PalestineFamineTabs from './components/palestineFamineTab';
import { palestineVideos as videos } from './fixtures'
import MuslimsAroundSection from './components/common-sections/muslimsAround';
import FAQSectionOtherPalestinePage from './components/common-sections/faqs';
import TotalImpactSection from './components/common-sections/totalImpacts';

const images = [Palestinepreramadan1, Palestinepreramadan2, Palestinepreramadan3, Palestinepreramadan4, Palestinepreramadan5, Palestinepreramadan6];

const PalestineFamineLandingPage = ({ lang = 'en' }) => {
  /* ----------------------------- Start variables ---------------------------- */
  const products = useSelector(state => selectProducts(state));
  const selectedcountryCode = useSelector(selectSelectedCountryCode) || 'AU';
  const seo = SeoData[selectedcountryCode];
  const [loaded, setLoaded] = useState(false);
  const [currentSlideImages, setCurrentSlideImages] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [whereMostNeededProducts, setWhereMostNeededProducts] = useState([0]);
  const [feedPalestineOrphanProducts, setFeedPalestineOrphanProducts] = useState([0, 0, 0, 0]);
  const [FAQs, setFAQs] = useState(palestineFamineFaqs)
  const [babyMilkProducts] = useState({
    title: lang === 'fr' ? 'Lait pour bébé' : lang === 'ar' ? 'حليب الأطفال' : 'BABY MILK',
    description: lang === 'fr' ? 'Fournir un approvisionnement urgent en lait pour bébé aux familles qui n’ont pas les moyens de fournir ce produit de première nécessité à leurs enfants en bas âge.' : lang === 'ar' ? 'توفير إمدادات عاجلة من حليب الأطفال للأسر التي لا تستطيع توفير هذا الغذاء الأساسي لأطفالها الرضع.' : 'Provide a delivery of an urgent baby milk supply to families who cannot afford to provide this staple to their infant children.',
    img: waterAidPhoto,
    products: [],
  });

  // Mobile Slider Variables
  const [sliderRefImages, instanceRefImages] = useKeenSlider({
    mode: 'free-snap',
    slideChanged(slider) {
      setCurrentSlideImages(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    slides: {
      origin: 'auto',
      perView: 1,
      spacing: 8,
    },
  });

  /* ------------------------------ Start methods ----------------------------- */

  const getAllProducts = () => {
    setIsLoaded(true);
    let tempMainBabyMilkProducts = [];
    let tempWhereMostProducts = [];
    let tempFeedPalestineOrphanProducts = [];
    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          // feed palestine orphan
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-315') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: lang === 'fr' ? `Nourrir un orphelin en Palestine` : lang === 'ar' ? `إطعام يتيم في فلسطين` : inner_item_product.name,
              description:
                lang === 'fr'
                  ? `Fournir un repas chaud à un enfant orphelin à Gaza et l'empêcher de dormir le ventre vide pendant une famine imminente.`
                  : lang === 'ar'
                    ? `توصيل وجبة ساخنة إلى 1 طفل يتيم في غزة وحمايتهم من النوم جائعا خلال المجاعة التي تلوح في الأفق.`
                    : inner_item_product.short_description || inner_item_product.description,
            });
            tempFeedPalestineOrphanProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-316') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: lang === 'fr' ? `Nourrir 10 orphelins en Palestine` : lang === 'ar' ? `إطعام 10 أيتام في فلسطين` : inner_item_product.name,
              description:
                lang === 'fr'
                  ? `Fournir un repas chaud à 10 orphelins à Gaza et l'empêcher de dormir le ventre vide pendant une famine imminente.`
                  : lang === 'ar'
                    ? `إطعام 10 أيتام في غزة ب 10 وجبات مغذية ساخنة وحمايتهم من النوم جائعا خلال المجاعة التي تلوح في الأفق.`
                    : inner_item_product.short_description || inner_item_product.description,
            });
            tempFeedPalestineOrphanProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-317') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: lang === 'fr' ? `Nourrir 20 orphelins en Palestine` : lang === 'ar' ? `إطعام 20 يتيما في فلسطين` : inner_item_product.name,
              description:
                lang === 'fr'
                  ? `Fournir un repas chaud à 20 orphelins à Gaza et l'empêcher de dormir le ventre vide pendant une famine imminente.`
                  : lang === 'ar'
                    ? `تقديم 20 وجبة مغذية ساخنة ل 20 يتيما في غزة وحمايتهم من النوم جائعا خلال المجاعة التي تلوح في الأفق.`
                    : inner_item_product.short_description || inner_item_product.description,
            });
            tempFeedPalestineOrphanProducts[2] = inner_item_product;
          }
          // Baby Milk
          if (inner_item_product.creator === 'EMR-PAL-BMP-2024-001') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: lang === 'fr' ? `Du lait pour bébé pendant 4 semaines en Palestine` : lang === 'ar' ? `حليب الأطفال لمدة 4 أسابيع في فلسطين` : 'Baby Milk for 4 Weeks in Palestine',
            });
            tempMainBabyMilkProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-PAL-BMP-2024-002') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName:
                lang === 'fr' ? `Du lait pour bébé pendant 4 semaines en Palestine` : lang === 'ar' ? `حليب الأطفال لمدة 8 أسابيع في فلسطين` : 'Baby Milk for 8 Weeks in Palestine',
            });
            tempMainBabyMilkProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-PAL-BMP-2024-003') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName:
                lang === 'fr' ? `Du lait pour bébé pendant 12 semaines en Palestine` : lang === 'ar' ? `حليب الأطفال لمدة 12 أسبوعًا في فلسطين` : 'Baby Milk for 12 Weeks in Palestine',
            });
            tempMainBabyMilkProducts[2] = inner_item_product;
          }
          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    tempWhereMostProducts[0] = babyMilkProducts;
    tempWhereMostProducts[0].products = tempMainBabyMilkProducts;
    tempMainBabyMilkProducts = tempMainBabyMilkProducts.filter(n => n)
    tempFeedPalestineOrphanProducts = tempFeedPalestineOrphanProducts.filter(n => n)
    setWhereMostNeededProducts(tempWhereMostProducts);
    setFeedPalestineOrphanProducts(tempFeedPalestineOrphanProducts);
  };

  /* ------------------------------- Start hooks ------------------------------ */
  // useEffect(() => {
  //   selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  // }, [selectedCurrencyStore]);
  useEffect(() => {
    lang === 'ar' ? setFAQs(palestineFamineFaqsArabic) : lang === 'fr' ? setFAQs(palestineFamineFaqsFrench) : setFAQs(palestineFamineFaqs)
  }, [lang]);
  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */

  return (
    <Layout className="!bg-white" isRamadanPage>
      {seo && (
        <Helmet>
          <title>{`${seo['crisisAndEmergencies']['palestine']['title']}`}</title>
          <meta name="title" content={`${seo['crisisAndEmergencies']['palestine']['title']}`} />
          <meta name="description" content={`${seo['crisisAndEmergencies']['palestine']['description']}`} />
          {/* <meta name="description" content={`Donate to Palestine appeal and provide emergency support. Make a difference with your donations through MATW Charity.`} /> */}
        </Helmet>
      )}

      <section className="bg-[#DFEEF2] sticky top-16 z-30 md:hidden p-2 md:p-4">
        <CrisisWidget
          ramadanMobileWidget
          className={'bg-[#DFEEF2] h-auto'}
          fixPrice={false}
          lang={lang}
          defaultProduct={{
            creator: 'MKD-MN-001',
            label: lang === 'ar' ? 'حيث يحتاج الأكثر' : "Where Most Needed",
            value: 255,
          }}
        />
      </section>

      <section className="flex justify-center">
        <img src={lang === 'fr' ? bannerFr : lang === 'ar' ? bannerAr : "https://cdn.matwproject.org/images/banners/MATW_PalestineFamine_Support.jpg"} alt="Donate to Palestine" className="max-[500px]:hidden w-full" />
        <img src={lang === 'fr' ? bannerMobileFr : lang === 'ar' ? bannerMobileAr : "https://cdn.matwproject.org/images/banners/MATW_PalestineFamine_Support.jpg"} alt="Donations to Palestine" className="min-[501px]:hidden w-full" />
      </section>
      {/* /* --------------------------------- Widget --------------------------------- */}
      <section className="bg-[#DFEEF2] hidden md:block sticky top-16 lg:top-24 z-30">
        <div className="max-w-[1160px] px-3 mx-auto">
          <CrisisWidget
            floating
            className={'bg-[#DFEEF2] h-auto pt-10'}
            fixPrice={false}
            lang={lang}
            defaultProduct={{
              creator: 'MKD-MN-001',
              label: lang === 'ar' ? 'حيث يحتاج الأكثر' : "Where Most Needed",
              value: 255,
            }}
          />
        </div>
      </section>
      <div className="hidden sm:block">
        <OurPromiseSection lang={lang} />
      </div>
      {/* ------------------- Products Section -------------------- */}
      <PalestineFamineTabs
        whereMostNeededProducts={whereMostNeededProducts}
        feedPalestineOrphanProducts={feedPalestineOrphanProducts}
        lang={lang}
      />
      <div className="sm:hidden">
        <OurPromiseSection lang={lang} />
      </div>
      {/* /* -------------------------------------------------------------------------- */}
      <div className="md:container md:mx-auto px-4 py-8 md:py-12" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
        <h1 className={`text-2xl md:text-[40px] text-[#253b7e] ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'} mb-5 md:mb-8 md:text-center`}>
          {lang === 'fr'
            ? <>Aidez à protéger les familles palestiniennes de la<span className="text-[#f60362]">famine.</span></>
            : lang === 'ar'
              ? <>ساعدوا في حماية الأسر الفلسطينية من<span className="text-[#f60362] mr-1">المجاعة.</span></>
              : <>Help protect Palestinian families from <span className="text-[#f60362]">starvation.</span></>
          }
        </h1>
        <div className={`mt-6 md:mt-8 text-[#78716C] leading-7 md:text-[18px] space-y-4 ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'}`}>
          {lang === 'fr'
            ? <>
              <p>
                Le peuple palestinien était confronté à des niveaux d’extrême pauvreté bien avant le récent conflit. Aujourd’hui, la situation ne cesse de s’aggraver. Les familles qui ont fui le conflit sont désormais confrontées à un nouvel ennemi : la famine. Alors que la faim sévère se répand dans les camps, provoquant une malnutrition aiguë, les enfants et les nourrissons deviennent particulièrement vulnérables.
              </p>
              <p>Des centaines de milliers de personnes sont confrontées à la famine et sont en danger imminent de mort en raison des troubles politiques.</p>
              <p>Les mères enterrent leurs nouveau-nés et les enfants dépérissent, faisant la queue pendant des heures pour mendier de la nourriture.</p>
              <p>
                Notre équipe MATW est sur le terrain tous les jours, fournissant des fournitures à partir de magasins de fournitures et travaillant en collaboration avec des organisations telles que l’Organisation caritative hachémite jordanienne (JHCO) pour fournir de la nourriture d’urgence, des soins de santé et des fournitures essentielles.
              </p>
              <p className="md:text-lg leading-5 text-[#253B7E]">Le peuple de Gaza a plus que jamais besoin de vous. Aidez-nous à lutter contre la famine.</p>
            </>
            : lang === 'ar'
              ? <>
                <p>
                  لقد كان شعب فلسطين يواجه مستويات الفقر المدقع قبل فترة طويلة من الصراع الأخير. الآن يستمر الوضع في التدهور. تواجه العائلات التي فرت من الصراع الآن عدوا جديدا: المجاعة. ومع انتشار الجوع الشديد في جميع أنحاء المخيمات مما يسبب سوء التغذية الحاد، يصبح الأطفال والرضع معرضين للخطر بشكل خاص.
                </p>
                <p>يواجه مئات الآلاف من الناس المجاعة وهم في خطر الموت الوشيك الناجم عن الاضطرابات السياسية.</p>
                <p>الأمهات يدفنون أطفالهن حديثي الولادة والأطفال يذبلون ، ويقفون في طابور لساعات يتوسلون للحصول على الطعام.</p>
                <p>
                  يتواجد فريق MATW على الأرض كل يوم ، حيث يوفر الإمدادات من متاجر الإمدادات ويعمل بالتعاون مع منظمات مثل الهيئة الخيرية الأردنية الهاشمية (JHCO) لتقديم المواد الغذائية العاجلة والرعاية الصحية والإمدادات الأساسية.
                </p>
                <p className="md:text-lg leading-5 text-[#253B7E]">إن سكان غزة بحاجة إليكم الآن أكثر من أي وقت مضى. ساعدونا في محاربة المجاعة.</p>
              </>
              : <>
                <p>
                  The people of Palestine have been facing extreme poverty levels long before the recent conflict. Now the situation continues to get worse.
                  Families who have escaped the conflict now face a new enemy: Famine. As severe hunger spreads throughout the camps causing acute
                  malnutrition, children and infants become especially vulnerable.
                </p>
                <p>Hundreds of thousands of people face starvation and are in imminent danger of death caused by political unrest. </p>
                <p>Mothers are burying their newborn babies and children are withering away, standing in line for hours begging for food.</p>
                <p>
                  Our MATW team is on the ground every day, providing supplies from supply stores and working in collaboration with organizations such as
                  the Jordanian Hashemite Charity Organisation (JHCO) to deliver urgent food, healthcare and essential supplies.
                </p>
                <p className="md:text-lg leading-5 text-[#253B7E]">The people of Gaza need you now more than ever before. Help us fight the famine. </p>
              </>
          }
        </div>
      </div>
      {/* ------------------------ Total Impact Section */}
      <TotalImpactSection lang={lang} />

      <div className="max-w-[1440px] mx-auto mt-8 md:mt-12">
        <section className="xl:px-20 mb-8 sm:mb-16">
          <div className="relative overflow-hidden flex flex-wrap">
            <div className="order-2 z-10 sm:order-1 bg-[#F60362] flex flex-col items-center md:items-start justify-center w-full md:max-w-[390px] md:top-1/2 md:-translate-y-1/2 right-16 bg-no-repeat bg-cover bg-center md:rounded-2xl md:absolute px-9 py-5 md:px-[48px] md:py-[38px]">
              <div className={`leading-[25px] w-full text-[25px] md:text-[30px] md:leading-[30px] text-white text-center ${lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-brandingBold'}`}>
                {lang === 'fr'
                  ? `La Palestine est au bord de la famine`
                  : lang === 'ar'
                    ? `فلسطين على حافة المجاعة`
                    : `Palestine is on the brink of famine`
                }
              </div>
              <p className={`md:text-[18px] mt-3 md:mt-5 text-white text-center ${lang === 'ar' ? 'font-[AlmariMedium]' : 'font-brandingMedium'}`}>
                {lang === 'fr'
                  ? `Laissez votre Zakat ou Sadaqah être leur bouée de sauvetage et votre entrée au Jannah`
                  : lang === 'ar'
                    ? `اجعل زكاتك أو صدقاتك هي شريان الحياة ودخولك الجنة`
                    : `Let your Zakat or Sadaqah be their lifeline and your entry to Jannah`
                }
              </p>
            </div>
            <div className="hidden sm:block absolute w-full h-full bg-[#00000085]" />
            <YoutubeVideoHero src={`https://www.youtube.com/embed/8fCYKifjeg4?controls=0&autoplay=1&loop=1&mute=1&playlist=8fCYKifjeg4`} />
          </div>
        </section>
      </div>

      {/* /* --------------------------------- Muslim Arounds ------------------------- */}
      <MuslimsAroundSection lang={lang} />
      {/* /* -------------------------------------------------------------------------- */}
      <section className="bg-[#F8F8F8] mt-5 pb-5 sm:py-8">
        {/* ----------------- videos section ---------------- */}
        <VideosSection youtube videos={videos} videoBoxClass={'p-0 bg-[#F8F8F8]'} />
        {/* ----------------- videos section --END-------------- */}

        {/* /* --------------------------------- Images --------------------------------- */}
        <section className="md:mt-16">
          <div className="md:container md:mx-auto flex justify-center flex-wrap ">
            <div className="w-full  flex-wrap justify-center items-center px-6 sm:px-0 relative">
              <div ref={sliderRefImages} className="keen-slider">
                {images.map((item, index) => {
                  return (
                    <div key={`carousel_images${index}`} className={`keen-slider__slide basis-full  flex justify-center rounded-xl sm:rounded-none`}>
                      {/* <div className={`bg-[url('../src/images/zakat/zakat-alfitr${index + 1}.jpeg')] bg-center rounded bg-cover h-[300px] w-full`} /> */}
                      <img src={item} alt={`carousel_images${index}`} className=" bg-top object-cover w-full object-top" />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="w-full  flex justify-center">
              {loaded && instanceRefImages.current && (
                <div className="dots flex pt-3 ">
                  {[...Array(instanceRefImages.current.track.details.slides.length).keys()].map(idx => {
                    return (
                      <button
                        key={idx}
                        onClick={() => {
                          instanceRefImages.current?.moveToIdx(idx);
                        }}
                        className={
                          'dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer' +
                          (currentSlideImages === idx ? ' active bg-[#253A7E]' : ' bg-gray-300')
                        }
                      ></button>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </section>
        {/* /* -------------------------------------------------------------------------- */}
      </section>

      <FAQSectionOtherPalestinePage lang={lang} FAQs={FAQs} />
      {/* /* -------------------------------------------------------------------------- */}
    </Layout>
  );
};
export default PalestineFamineLandingPage;