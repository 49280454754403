export const generateLinkWithLang = (path, lang) => {
    // console.log("generat linking",path, lang)
    // if (!lang || lang !== 'fr' || lang !== 'ar' || lang !== 'es') {
    // // if (!lang) {
    //     return path; // No language, return the original path
    // }
    if (lang === 'fr' || lang === 'ar' || lang === 'es') {
        return `/${lang}${path.startsWith('/') ? path : '/' + path}`; // Ensure the path starts with a '/'
    } else {
        return path; // No language, return the original path
    }
};