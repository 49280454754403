const TrackAdrollGlobalPixelUKScript = adroll_obj => {
  const addScriptOnHead = () => {
    const script = document.createElement('script');
    // Convert products to a JSON string to ensure proper formatting
    const productsArray = JSON.stringify(
      adroll_obj.products.map(item => ({
        price: item.price,
        product_id: item.product_id,
        quantity: item.quantity,
      })),
    );
    script.async = true;
    script.type = 'text/javascript';
    script.id = 'adroll_track_script';
    script.innerHTML = `adroll.track("purchase", {
        'ORDER_ID': '${adroll_obj.ORDER_ID}',
        'USER_ID': ${adroll_obj.USER_ID},
        'conversion_value': ${adroll_obj.conversion_value},
        'currency': '${adroll_obj.currency}',
        'products': ${productsArray}
      });`;
    document.head.appendChild(script);
  };
  var href = window.location.href;
  var scripts = document.getElementsByTagName('script');
  var is_exist = false;
  if (href.includes('matwproject.org.uk')) {
  // if (href.includes('localhost')) {
    for (var i = scripts.length; i--; ) {
      if (scripts[i].id === 'adroll_track_script') is_exist = true;
    }
    !is_exist && addScriptOnHead();
  }
};

export default TrackAdrollGlobalPixelUKScript;
