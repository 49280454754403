import { useEffect, useState } from 'react';
import Layout from '../../components/general/layout';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode } from '../../store/user';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import Accordion from '../../components/general/accordion';
import muslimAroundImg from '../../images/landings/qurban/muslim-arounds.jpg';
import { qurbanFaqs } from './faqs';
import { selectProducts } from '../../store/products';
import QurbanTabs from './components/qurbanTabs';
import QurbanSlider from './components/qurban-slider';
import sheepBundleImg from '../../images/landings/qurban/sheep-bundle.png';
// import cowBundleImg from '../../images/landings/qurban/cow-bundle.png';
import qurbanSlider1 from '../../images/landings/qurban/qurban-slider1.jpg';
import qurbanSlider2 from '../../images/landings/qurban/qurban-slider2.jpg';
import qurbanSlider3 from '../../images/landings/qurban/qurban-slider3.jpg';
import qurbanSlider4 from '../../images/landings/qurban/qurban-slider4.jpg';
import qurbanSlider5 from '../../images/landings/qurban/qurban-slider5.jpg';
import qurbanSlider6 from '../../images/landings/qurban/qurban-slider6.jpg';

const images = [
  { src: qurbanSlider1, alt: 'Affordable Qurbani in 2024 with MATW Project, feed people in need' },
  { src: qurbanSlider2, alt: 'Qurbani Eid 2024: Feed thousands of people, make your qurbani today' },
  { src: qurbanSlider3, alt: 'When is Qurbani Eid 2024: Do it in time with MATW' },
  { src: qurbanSlider4, alt: 'How much is Qurbani 2024, see our prices above.' },
  { src: qurbanSlider5, alt: '' },
  { src: qurbanSlider6, alt: '' }
];

const CheapestQurbani2024LandingPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedcountryCode = useSelector(selectSelectedCountryCode) || 'AU';
  const products = useSelector(state => selectProducts(state));
  const [isLoaded, setIsLoaded] = useState(false);
  const seo = SeoData[selectedcountryCode];
  const [sheepGoat, setSheepGoat] = useState({
    title: 'qurbani sheep/goat',
    description: '',
    products: [],
    checkboxProducts: [],
    bundleProduct: {
      product: {},
      image: sheepBundleImg,
      title: 'Ultimate Qurbani',
      type: 'goat',
    }
  });
  const [cowShare, setCowShare] = useState({
    title: 'cow share',
    description: '',
    products: [],
    checkboxProducts: [],
    bundleProduct: {
      product: {},
      image: sheepBundleImg,
      title: 'Ultimate Qurbani',
      // type: 'cow',
      type: 'goat',
    }
  });
  const [cow, setCow] = useState({
    title: 'cow',
    description: '',
    products: [],
  });
  const [foodAid, setFoodAid] = useState({
    title: 'Food Aid',
    description: '',
    products: [],
  });
  const [zakat, setZakat] = useState({
    title: 'Zakat',
    description: '',
    products: [],
  });
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start Methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    let tempSheepGoatProducts = [];
    let tempSheepGoatBundleProduct = {};
    let tempCowShareProducts = [];
    // let tempCowShareBundleProduct = {};
    let tempCowProducts = [];
    let tempFoodAidProducts = [];
    let tempZakatProducts = [];
    let tempCheckBoxProducts = [];

    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          // Checkbox products
          if (inner_item_product.creator === 'TK-QB-2024-FA-00') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Palestine 2kg Meat',
            });
            tempCheckBoxProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'TK-QB-2024-FA-02') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Palestine 10kg of Rice',
            });
            tempCheckBoxProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'TK-QB-2024-EG28') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Qurban Eid Gift',
            });
            tempCheckBoxProducts[2] = inner_item_product;
          }

          // SheepGoat
          if (inner_item_product.creator === 'QB-2024-S17') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Palestine',
            });
            tempSheepGoatProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S18') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: 'Egypt',
              name: 'Palestinian Refugees',
            });
            tempSheepGoatProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S19') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Yemen',
            });
            tempSheepGoatProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S20') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Africa',
            });
            tempSheepGoatProducts[3] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S24') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: 'Syria',
              name: 'Syrian Refugees',
            });
            tempSheepGoatProducts[4] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S23') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Afghanistan',
            });
            tempSheepGoatProducts[5] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S25') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Jordan',
            });
            tempSheepGoatProducts[6] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S21') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Pakistan',
            });
            tempSheepGoatProducts[7] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S27') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Lebanon',
            });
            tempSheepGoatProducts[8] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S22') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Bangladesh',
            });
            tempSheepGoatProducts[9] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-UQ-01') {
            tempSheepGoatBundleProduct = inner_item_product;
          }

          // Cow share
          // if (inner_item_product.creator === 'QB-2024-S9') {
          //   inner_item_product = Object.assign({}, inner_item_product, {
          //     name: 'Palestine',
          //   });
          //   tempCowShareProducts[0] = inner_item_product;
          // }
          if (inner_item_product.creator === 'QB-2024-S11') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'India',
            });
            tempCowShareProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S10') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: 'Egypt',
              name: 'Palestinian Refugees',
            });
            tempCowShareProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S12') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Africa',
            });
            tempCowShareProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S16') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Lebanon',
            });
            tempCowShareProducts[3] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S14') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Pakistan',
            });
            tempCowShareProducts[4] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S15') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Turkey',
            });
            tempCowShareProducts[5] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S13') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Bangladesh',
            });
            tempCowShareProducts[6] = inner_item_product;
          }
          // if (inner_item_product.creator === 'QB-2024-UQ-02') {
          //   tempCowShareBundleProduct = inner_item_product;
          // }

          // Cow
          // if (inner_item_product.creator === 'QB-2024-S1') {
          //   inner_item_product = Object.assign({}, inner_item_product, {
          //     name: 'Palestine',
          //   });
          //   tempCowProducts[0] = inner_item_product;
          // }
          if (inner_item_product.creator === 'QB-2024-S3') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'India',
            });
            tempCowProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S2') {
            inner_item_product = Object.assign({}, inner_item_product, {
              // name: 'Egypt',
              name: 'Palestinian Refugees',
            });
            tempCowProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S4') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Africa',
            });
            tempCowProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S8') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Lebanon',
            });
            tempCowProducts[3] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S6') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Pakistan',
            });
            tempCowProducts[4] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S7') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Turkey',
            });
            tempCowProducts[5] = inner_item_product;
          }
          if (inner_item_product.creator === 'QB-2024-S5') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Bangladesh',
            });
            tempCowProducts[6] = inner_item_product;
          }

          // Food Aid
          if (inner_item_product.creator === 'QB-2024-FA-03') {
            tempFoodAidProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'FWD-DIS-RBG-2023-01-052') {
            tempFoodAidProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-316') {
            tempFoodAidProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-317') {
            tempFoodAidProducts[3] = inner_item_product;
          }

          // Zakat
          if (inner_item_product.creator === 'RM24-ZM-013') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Give Zakat Al Mal'
            });
            tempZakatProducts[1] = inner_item_product;
            inner_item_product = Object.assign({}, inner_item_product, {
              staticLink: '/zakat-calculator',
              name: 'Calculate your Zakat',
              creator: 'RM24-ZM-013-'
            });
            tempZakatProducts[0] = inner_item_product;
          }

          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });

    tempSheepGoatProducts = tempSheepGoatProducts.filter(n => n)
    tempCowShareProducts = tempCowShareProducts.filter(n => n)
    tempCowProducts = tempCowProducts.filter(n => n)
    tempFoodAidProducts = tempFoodAidProducts.filter(n => n)
    tempZakatProducts = tempZakatProducts.filter(n => n)
    tempCheckBoxProducts = tempCheckBoxProducts.filter(n => n)

    setSheepGoat({
      ...sheepGoat,
      products: tempSheepGoatProducts,
      checkboxProducts: tempCheckBoxProducts,
      bundleProduct: { ...sheepGoat.bundleProduct, product: tempSheepGoatBundleProduct, type: 'goat', }
    });
    setCowShare({
      ...cowShare,
      products: tempCowShareProducts,
      checkboxProducts: tempCheckBoxProducts,
      bundleProduct: { ...cowShare.bundleProduct, product: tempSheepGoatBundleProduct, type: 'goat', }
      // bundleProduct: { ...cowShare.bundleProduct, product: tempCowShareBundleProduct, type: 'cow', }
    })
    setCow({
      ...cow,
      products: tempCowProducts
    })
    setFoodAid({
      ...foodAid,
      products: tempFoodAidProducts
    });
    setZakat({
      ...zakat,
      products: tempZakatProducts
    })
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */

  return (
    <Layout className="!bg-white">
      {seo && (
        <Helmet>
          <title>{`${seo['cheapest-qurbani-2024']['title']}`}</title>
          <meta name="title" content={`${seo['cheapest-qurbani-2024']['title']}`} />
        </Helmet>
      )}

      {/* <section className="bg-[#DFEEF2] sticky top-16 z-30 md:hidden p-2 md:p-4">
        <CrisisWidget
          ramadanMobileWidget
          className={'bg-[#DFEEF2] h-auto'}
          fixPrice={true}
          defaultProduct={{
            creator: "MKD-MN-001",
            label: "Where Most Needed",
            value: 255
          }}
        />
      </section> */}
      {/* /* --------------------------------- Widget --------------------------------- */}
      {/* <section className="bg-[#DFEEF2] hidden md:block sticky top-16 lg:top-24 z-30">
        <div className="max-w-[1160px] px-3 mx-auto">
          <CrisisWidget
            floating
            className={'bg-[#DFEEF2] h-auto pt-10'}
            fixPrice={true}
            defaultProduct={{
              creator: "MKD-MN-001",
              label: "Where Most Needed",
              value: 255
            }}
          />
        </div>
      </section> */}
      {/* /* -------------------------------------------------------------------------- */}

      {/* ------------------------------- Products Section ---------------------------- */}
      <QurbanTabs
        hideWidget
        headerText="Cheapest Qurbani in 2024 with MATW from Just £24. Choose our most affordable qurbani options that bring high quality meat to people in need."
        // footerText="Don’t wait. Donate your Qurbani Today."
        footerText=""
        sheepGoatProducts={sheepGoat}
        cowShareProducts={cowShare}
        cowProducts={cow}
        foodAidProducts={foodAid}
        zakatProducts={zakat}
      />
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* -------------------------------- helped us ------------------------------- */}
      <div className="md:container md:mx-auto px-4">
        <div className="flex flex-col py-8 md:py-16">
          <p className="text-[#253B7E] md:text-center leading-[30px] md:leading-[50px] text-[25px] md:text-[40px] mb-4 md:mb-6 font-brandingBold">
            In 2023, you helped us reach more than 6 million people with your Qurbani. It’s an Amanah we at MATW take very seriously.
          </p>
          <p className="md:text-lg text-[#78716C] font-brandingMedium- font-['Montserrat'] text-left sm:pr-12 pr-0">
            Our MATW teams work hard to deliver your Qurbani donations within the most impoverished
            communities around the world. Last year, we delivered within the most remote areas and
            conflict zones including Palestine, Yemen and Myanmar.
            <br />
            <br />
            From procuring livestock and tending to the animals, right through to delivery, we ensure
            that every step of the Qurbani process is meticulously adhered to with care and in accordance
            with Islamic guidelines and principles.
          </p>
        </div>
        <div className="flex flex-wrap sm:flex-nowrap mt-8 justify-center gap-x-0 sm:gap-x-8 gap-y-8">
          <div className={`basis-full md:basis-1/2 sm:bg-[url('../src/images/landings/qurban/muslim-arounds.jpg')] bg-center bg-cover rounded-md`}>
            <img className="sm:hidden" src={muslimAroundImg} alt="qurbani for 2024, feeding people through qurbani for eid 2024" />
          </div>
          <div className="basis-full md:basis-1/2 flex flex-col justify-center">
            <div className="text-[25px] md:text-[40px] text-[#253A7E] font-bold leading-[30px] md:leading-[45px]">
              Muslims Around The World (MATW) for YOUR Qurbani
            </div>
            <p className="md:text-lg text-[#F60362] font-brandingMedium mt-3 sm:mt-6 text-left">
              Our team works hard to fulfill your obligation and bring joy to as many families as possible during Eid Al Adha.
            </p>
            <p className="md:text-lg text-[#78716C] font-brandingMedium text-left sm:pr-12 pr-0">
              We ensure that the most vulnerable families are prioritised to receive
              the best quality Qurbani meat, delivered fresh during Eid.
              This includes orphans, widows and the elderly.
              <br />
              <br />
              All of Muslim Around The World’s Qurbani orders received from donors are
              processed and done fresh on Eid.
              <br />
              <br />
              Our teams work tirelessly in preparation for Eid so that from the 10th to
              13th days of Dhul Hijjah, not a single moment is spared. Qurbani is processed
              and delivered fresh to vulnerable families with each Qurbani pack containing
              3-5kg of meat and feeding a family of 5-8 people.
            </p>
          </div>
        </div>
      </div>
      {/* /* -------------------------------------------------------------------------- */}
      <div className="md:container md:mx-auto px-4 py-8 md:py-12">
        <p className="text-2xl md:text-[40px] text-[#253b7e] font-brandingBold mb-5 md:mb-8 md:text-center">
          How does it work?
        </p>
        <div className="mt-6 md:mt-8 text-[#78716C] leading-7 md:text-[18px] font-brandingMedium- font-['Montserrat'] space-y-4">
          <p>
            Our process is simple. When the time comes to give your Qurbani donation, simply donate and our MATW
            team takes care of the rest. Start by selecting where you’d like to send your Qurbani and the quantity
            you’d like to donate. The livestock is purchased and checked ahead of time with our team ensuring the
            animals meet the checking requirements. Your Qurbani is then processed during Eid and distributed to the
            poorest families!
            <br />
            <br />
            Reap the rewards of the 10 best days of the year – the blessed first days of Dhul Hijjah! Give your Qurbani today.
          </p>
        </div>
      </div>

      {/* /* --------------------------------- Images --------------------------------- */}
      <QurbanSlider images={images}/>
      {/* /* -------------------------------------------------------------------------- */}

      <section className="max-w-[1440px] md:mx-auto px-4 xl:px-20 mt-10">
        <h2 className="text-[#253B7E] text-left  sm:text-center mb-6 md:mb-8 text-[25px] sm:text-[50px] font-brandingBold">
          Affordable Qurbani in 2024 with MATW: <span className="text-[#F60362]"> FAQ’s</span>
        </h2>
        <div className="grid grid-cols-3 gap-x-16 gap-y-8">
          <div className="col-span-3 sm:col-span-1">
            <p className="text-[#253B7E] text-[18px] md:text-2xl font-brandingBold">{qurbanFaqs[0].question}</p>
            <p className="text-base font-brandingMedium mt-3 text-[#78716C]">{qurbanFaqs[0].answer}</p>
          </div>
          <div className="col-span-3 sm:col-span-1">
            <p className="text-[#253B7E] text-[18px] md:text-2xl font-brandingBold">{qurbanFaqs[1].question}</p>
            <p className="text-base font-brandingMedium mt-3 text-[#78716C]">{qurbanFaqs[1].answer}</p>
          </div>
          <div className="col-span-3 sm:col-span-1">
            <p className="text-[#253B7E] text-[18px] md:text-2xl font-brandingBold">{qurbanFaqs[2].question}</p>
            <p className="text-base font-brandingMedium mt-3 text-[#78716C]">{qurbanFaqs[2].answer}</p>
          </div>
        </div>
        <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
          {qurbanFaqs.map((item, index) => {
            return (
              index > 2 && (
                <div className="bg-white rounded-md p-4 mt-2 w-full shadow-md" key={`faq${index}`}>
                  <Accordion
                    key={`keyallfaq${index}`}
                    shadow
                    title={item.question}
                    index={index}
                    level={1}
                    noBorder={true}
                    className="!text-[#253B7E] text-lg font-brandingBold"
                  >
                    {item.answer}
                  </Accordion>
                </div>
              )
            );
          })}
        </div>
      </section>
      {/* /* -------------------------------------------------------------------------- */}
    </Layout>
  );
};
export default CheapestQurbani2024LandingPage;