import React, { useEffect, useState, useRef } from 'react';
import Layout from '../../components/general/layout';
import OrphanCrisisWidget from '../orphans/OrphanCrisesWidget';
import CrisisWidget from './components/crisisWidget';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import { selectProducts } from '../../store/products';
import { CustomButton, OurPromiseSection } from '../../components/general';
import MaxWidthContainer from '../../components/general/maxWidthContainer';
import useHandleAddProduct from '../../utils/handleAddProduct';
import useWindowSize from '../../hooks/general/useWindowSize';
import banner from '../../images/landings/countries-crisis/countries-in-crisis-desktop-banner.jpg';
import CountriesCrisisTabs from './components/countries-crisis-tabs';
import ShowCurrencies from '../../utils/showCurrencies';
import matwGroundImg from '../../images/landings/countries-crisis/little-gril.jpg';
import carouselImage from '../../images/landings/countries-crisis/countries-carousel.png';

const CountriesInCrisisLandingPage = () => {
  // const banner = 'https://cdn.matwproject.org/images/banners/bangladesh-banner.webp';
  // const bannerMobile = 'https://cdn.matwproject.org/images/banners/bangladesh-banner-mobile.jpg';
  // const matwGroundImg = 'https://cdn.matwproject.org/images/landings/crisis/bangladesh-page-image.webp';

  const products = useSelector(state => selectProducts(state));
  const [foodAidProducts, setFoodAidProducts] = useState([0, 0, 0]);
  const [medicalAidProducts, setMedicalAidProducts] = useState([0, 0, 0]);
  const [orphansSupportProducts, setOrphansSupportProducts] = useState([0]);
  const [whereMostNeededProducts, setWhereMostNeededProducts] = useState([0]);
  const [matwOnGroundProduct, setMatwOnGroundProduct] = useState();
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const seo = SeoData[selectedcountryCode];
  const [isLoaded, setIsLoaded] = useState(false);
  const [addProduct] = useHandleAddProduct();
  const { width } = useWindowSize();
  const isMobile = width <= 768;

  const getAllProducts = () => {
    setIsLoaded(true);
    let foodAidProductsList = [];
    let medicalAidProductsList = [];
    let orphansSupportProductsList = [];
    let whereMostNeededProductsList = [];
    let matwOnGroundProductItem;
    products.forEach(item => {
      item.sub_categories.forEach(inner_item => {
        inner_item.products.forEach(inner_item_product => {
          if (inner_item_product.creator === 'EMR-BAN-RBB-2024-01') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: '40 Kg Rice Bag',
            });
            foodAidProductsList[0] = inner_item_product;
          } else if (inner_item_product.creator === 'EMR-BAN-FPB-2024-02') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'Emergency Food Pack',
            });
            foodAidProductsList[1] = inner_item_product;
          } else if (inner_item_product.creator === 'EMR-BAN-BFB-2024-07') {
            foodAidProductsList[2] = inner_item_product;
          } else if (inner_item_product.creator === 'EMR-BAN-DSB-2024-06') {
            medicalAidProductsList[0] = inner_item_product;
          } else if (inner_item_product.creator === 'EMR-BAN-HCB-2024-05') {
            medicalAidProductsList[1] = inner_item_product;
          } else if (inner_item_product.creator === 'EMR-BAN-EMB-2024-08') {
            orphansSupportProductsList[0] = inner_item_product;
          } else if (inner_item_product.creator === 'EMR-BAN-BTS-2024-04') {
            orphansSupportProductsList[1] = inner_item_product;
          } else if (inner_item_product.creator === 'EMR-BAN-ORP-2024-03') {
            orphansSupportProductsList[2] = inner_item_product;
          }

          if (inner_item_product.creator === 'EMR-LEB-24-000') {
            matwOnGroundProductItem = inner_item_product;
            whereMostNeededProductsList[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-LEB-24-001') {
            whereMostNeededProductsList[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-LEB-24-002') {
            whereMostNeededProductsList[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-LEB-24-003') {
            whereMostNeededProductsList[3] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-LEB-24-004') {
            whereMostNeededProductsList[4] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-LEB-24-005') {
            whereMostNeededProductsList[5] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-LEB-24-006') {
            whereMostNeededProductsList[6] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-LEB-24-007') {
            whereMostNeededProductsList[7] = inner_item_product;
          }
        });
      });
    });

    foodAidProductsList = foodAidProductsList.filter(n => n);
    medicalAidProductsList = medicalAidProductsList.filter(n => n);
    orphansSupportProductsList = orphansSupportProductsList.filter(n => n);
    whereMostNeededProductsList = whereMostNeededProductsList.filter(n => n);

    // console.log(matwOnGroundProductItem);

    setFoodAidProducts(foodAidProductsList);
    setMedicalAidProducts(medicalAidProductsList);
    setOrphansSupportProducts(orphansSupportProductsList);
    setWhereMostNeededProducts(whereMostNeededProductsList);
    setMatwOnGroundProduct(matwOnGroundProductItem);
  };

  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  const faqsRef = useRef(null);
  const moreInfoRef = useRef(null);
  const appealsRef = useRef(null);
  const startRef = useRef(null);
  const endRef = useRef(null);

  return (
    <Layout className={`${!isMobile && 'bg-[#fff]'}`}>
      {seo && (
        <Helmet>
          <title>{seo['countries-in-crisis']['title']}</title>
          <meta name="title" content={seo['countries-in-crisis']['title']} />
          <meta name="description" content={seo['countries-in-crisis']['description']} />
        </Helmet>
      )}

      <section className="bg-[#DFEEF2] sticky top-16 z-30 md:hidden p-2 md:p-4">
        <OrphanCrisisWidget
          ramadanMobileWidget
          className={'bg-[#DFEEF2] h-auto'}
          fixPrice={false}
          defaultProduct={{
            creator: 'EMR-LEB-24-000',
            label: 'Lebanon Emergency Appeal',
            value: 674,
          }}
        />
      </section>
      <section className="flex justify-center">
        <img src={banner} alt="banner" className="w-full" />
        {/* <img src={bannerMobile} alt="banner" className="min-[501px]:hidden w-full" /> */}
      </section>
      <section className="bg-[#DFEEF2]">
        <section className="max-w-[1440px] mx-auto">
          <div className="w-full hidden lg:mx-auto px-4 xl:px-10 py-4 md:py-6 md:flex justify-center items-center">
            <CrisisWidget
              floating
              className={'bg-[#DFEEF2] px-6 h-auto pt-10'}
              defaultProduct={{
                creator: 'EMR-LEB-24-000',
                label: 'Lebanon Emergency Appeal',
                value: 674,
              }}
            />
          </div>
        </section>
      </section>
      <section id="appeals" ref={appealsRef}>
        {/* {console.log("on bang appeal=",whereMostNeededProducts)} */}
        <CountriesCrisisTabs
          foodAidProducts={foodAidProducts}
          medicalAidProducts={medicalAidProducts}
          orphansSupportProducts={orphansSupportProducts}
          whereMostNeededProducts={whereMostNeededProducts}
        />
      </section>
      <OurPromiseSection childClasses={'!bg-[#fff]'} />
      <section
        className="xl:px-10 pt-5 sm:pt-12 bg-[#fff]"
        id="more-info"
        ref={element => {
          moreInfoRef.current = element;
          startRef.current = element;
        }}
      >
        <MaxWidthContainer className="!max-w-[1440px] !px-4 md:!px-6">
          <div className="text-left font-brandingBold sm:text-center font-bold text-[#093686] leading-[30px] sm:leading-[55px] text-2xl sm:text-5xl w-full sm:w-10/12 xs:mb-4 md:mb-0 mx-auto">
            <span className="text-[#F60362] mx-1">Millions</span>
            suffering.
            <span className="text-[#F60362] inline-block mx-1">Palestinians, Syrians, Lebanese. </span>
            Your crucial donations will help us save lives.
            <span className="text-[#F60362] mx-1">Donate Now.</span>
          </div>
          <div className="pt-2 sm:pt-10 leading-5">
            <div className="font-brandingBold sm:text-center font-bold text-[#093686] leading-[30px] sm:leading-[55px] text-2xl sm:text-3xl w-full sm:w-10/12 xs:mb-4 md:mb-0 mx-auto">
              <span className="text-[#00a3da]">The WORLD</span> is facing a<span className="text-[#00a3da]"> CRISIS</span>.
              <br />
              This catastrophe is sadly impacting
              <span className="text-[#00a3da]"> MILLIONS</span>. Palestinians, Syrians, and Lebanese
              <span className="text-[#00a3da]"> need HELP!</span>
              <br />
              <div className="text-[#000] pt-7 "> Allah says in the Qur'an:</div>
              <br />
              <em>
                "They give food in spite of love for it to the needy, the orphan, and the captive, [saying],
                <br />
                'We feed you only for the countenance of Allah. We wish not from you reward or gratitude.' [76:8]"
              </em>
              <br />
              <div className="py-7">
                <span className="text-[#00a3da]"> The never-ending nightmare</span> which has impacted
                <span className="text-[#00a3da]"> Gaza in Palestine</span> has now entered
                <span className="text-[#00a3da]"> Lebanon</span>.
                <br />
                With <span className="text-[#00a3da]">millions</span> of lives being changed forever in Palestine,
                <span className="text-[#00a3da]"> hundreds of thousands</span> of lives are now at risk in Lebanon within the space of a week!
              </div>
            </div>
          </div>
        </MaxWidthContainer>
      </section>
      <section id="matw-ground" className="bg-[#fff]">
        <MaxWidthContainer className="!max-w-[1440px] !px-4 md:!px-6">
          <div className="w-full flex flex-wrap px-4 md:px-0 pt-4- md:pt-8 pt-8 pb-8 md:mb-0">
            <div className="basis-full md:basis-1/2">
              <img src={matwGroundImg} alt="" className="max-h-[490px] rounded-lg shadow-lg mx-auto" />
            </div>
            <div className="basis-full md:basis-1/2 pl-0 md:pl-2">
              <h1 className="text-2xl font-medium md:text-4xl text-[#253B7E] mt-4 md:mt-0 mb-4 md:mb-8-">MATW on the ground in Gaza and Lebanon</h1>
              <div className="text-[18px] text-[#78716C] font-light mb-3">
                MATW are on the ground delivering vital relief
                <span className="text-[#00a3da]"> across Gaza </span> daily and in
                <span className="text-[#00a3da]"> Lebanon </span> where the number of displaced people is rising every single hour.
                <br />
                <br />
                Please donate
                <div className="inline-flex">
                  <div className="text-[#00a3da] mx-1">{ShowCurrencies(selectedCurrencyStore || 'AUD', 500)}</div>
                  <div className="text-[#00a3da] mx-1">{ShowCurrencies(selectedCurrencyStore || 'AUD', 250)}</div>
                  <div className="text-[#00a3da] mx-1">{ShowCurrencies(selectedCurrencyStore || 'AUD', 100)}</div>
                </div>
                or whatever you can afford as this assistance will go a long way in relieving the pain and distress of{' '}
                <em>orphans, widows, and the elderly</em>.
              </div>
              <CustomButton
                onClicked={() => {
                  matwOnGroundProduct && addProduct({ product: matwOnGroundProduct, currency: selectedCurrencyStore || 'AUD' });
                }}
                title={'Donate Now'}
                className="!block !w-auto text-white text-sm md:text-lg transition-all mt-2 duration-200 bg-[#f60362] hover:bg-[#00a3da] h-[48px] px-4 sm:px-6 rounded-md"
              />
            </div>
          </div>
        </MaxWidthContainer>
      </section>
      <section className="bg-[#F8F8F8] pt-5 pb-5 sm:py-8">
        {/* /* --------------------------------- Images --------------------------------- */}
        <section className="mt-4 md:mt-12">
          <div className="md:container md:mx-auto flex justify-center flex-wrap ">
            <div className="w-full  flex-wrap justify-center items-center px-6 sm:px-0 relative">
              <div className={`basis-full flex justify-center rounded-xl sm:rounded-none`}>
                <img src={carouselImage} alt={''} className=" bg-top object-cover w-full object-top" />
              </div>
            </div>
          </div>
        </section>
        {/* /* -------------------------------------------------------------------------- */}
      </section>
      <section
        id="faqs"
        ref={element => {
          faqsRef.current = element;
          endRef.current = element;
        }}
        className="bg-[#fff] px-4 md:container md:mx-auto mt-8 md:mt-12"
      >
        <div className="faq-section">
          <h1 className="text-2xl font-medium md:text-4xl text-[#253B7E] mt-4 md:mt-0 mb-4 md:mb-8-">
            Middle Eastern <span className="text-[#00a3da]">CATASTROPHE UNFOLDING</span>
          </h1>
          <p className="text-[18px] text-[#78716C] font-light">
            We operate on a <span className="text-[#00a3da]">100% Donation Policy</span> so rest assured, <span className="text-[#00a3da]">100%</span>{' '}
            of your contributions will reach the Palestinian people directly. This project is also Zakat Applicable.
            <br />
            <br />
            The people of <span className="text-[#00a3da]">Palestine</span> are facing a terrible nightmare, coping with a severe conflict, blockades,
            and the near-total collapse of basic services. Access to electricity, clean water, and medical care is in short supply, and the impact on
            civilians is catastrophic. If we count those missing, the death toll stands at more than <span className="text-[#00a3da]">50,000</span>,
            including <span className="text-[#00a3da]">16,500 children</span>. Another <span className="text-[#00a3da]">100,000+</span> are injured,
            many with catastrophic life-changing ailments.
            <br />
            <br />
            <span className="text-[#00a3da]">And now this conflict has started to spillover into Lebanon, intensifying a regional crisis.</span>
            <br />
            <br />
            Here, a nation already suffering from a <span className="text-[#00a3da]">crippling economic collapse</span> is now dealing with an even
            more difficult situation.
            <br />
            <br />
            <span className="text-[#00a3da]">Hundreds of thousands</span> of people have now been displaced due to the escalating conflict, adding to
            the suffering of a nation where over <span className="text-[#00a3da]">80%</span> of the population live in poverty.
            <br />
            <br />
            In just a week over <span className="text-[#00a3da]">more than a thousand</span> people have been killed, including{' '}
            <span className="text-[#00a3da]">many women and children</span>.
            <br />
            <br />
            This disaster has left many families <em>homeless, hopeless, and waiting for our assistance.</em>
            <br />
            <br />
            <em>We're there to answer the call, will you join us?</em>
          </p>
        </div>
      </section>
    </Layout>
  );
};

export default CountriesInCrisisLandingPage;
