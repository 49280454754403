import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../store/user';
import ShowCurrencies from '../../utils/showCurrencies';
import SelectCurrency from '../../components/general/selectCurrency';
import ReactSelect from 'react-select';
import ConvertUSD from '../../utils/convertUSD';
import useHandleAddProduct from '../../utils/handleAddProduct';
import { selectProducts } from '../../store/products';

const styles = {
  control: base => ({
    ...base,
    height: '48px',
    border: 'none',
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.05)',
  }),
  menuList: base => ({
    ...base,
    '::-webkit-scrollbar': {
      width: '6px',
      height: '0px',
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  }),
  shadow: {
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)',
  },
};

const CrisisWidget = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const { className, defaultProduct, mode, donateBtnText, arabic, fixPrice, onShowModal } = props;
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [productAmount, setProductAmount] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentCurrency, setCurrentCurrency] = useState();
  const [productsList, setProductsList] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [addProduct] = useHandleAddProduct();
  const [selectedProduct, setSelectedProduct] = useState(defaultProduct);
  const products = useSelector(state => selectProducts(state));
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    let tempProductsList = [];
    let crisisWidgetProducts = [];

    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          if (inner_item.name === 'Build A Water Well') {
            if (inner_item_product.creator === 'MKD-WEL-SDQ-2023-01') {
              crisisWidgetProducts.push(inner_item_product); // Water well
              tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
            }
          }
          if (inner_item.name === 'Orphans') {
            if (inner_item_product.creator === 'MKD-SDQ-OAW-2023-02-119') {
              crisisWidgetProducts.push(inner_item_product); // Support an Orphan Centre
              tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
            }
          }
          if (inner_item.name === 'Caravan') {
            if (inner_item_product.creator === 'EM-SDQ-EQ1-02-2023') {
              crisisWidgetProducts.push(inner_item_product); // Emergency Caravan Long Term Shelter
              tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
            }
          }
          if (inner_item.name === 'Palestine') {
            if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') {
              crisisWidgetProducts.push(inner_item_product); // Palestine Appeal
              tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
            }
          }
          if (inner_item.name === 'Make A Donation') {
            crisisWidgetProducts.push(inner_item_product);
            tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
          }
          if (inner_item.name === 'Winter Campaign') {
            if (inner_item_product.creator === 'MKD-EMR-WNT-2023-01') {
              crisisWidgetProducts.push(inner_item_product); // Keep Orphans Warm Where Most Needed
              tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
            }
          }
          if (defaultProduct) {
            if (inner_item_product.creator === defaultProduct.creator) {
              setSelectedProduct(inner_item_product);
            }
          } else {
            if (inner_item.name === 'Make A Donation') {
              setSelectedProduct(inner_item.products[0]);
            }
          }
          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });

    setProductsList(tempProductsList);
    setAllProducts(crisisWidgetProducts);
  };
  const handleConvert = (currency, value) => {
    return Math.round(ConvertUSD(currency, value, false));
  };
  const handleSelectedProduct = product => {
    props.handleProductName(product.label);

    allProducts.map(item => item.creator === product.creator && setSelectedProduct(item));
  };
  const handleSelectedItem = (currency, index) => {
    let tempSelectedPrice = {};
    tempSelectedPrice.index = index;
    tempSelectedPrice.value = suggestedPrices[index][currency.toLowerCase()];
    setSelectedPrice(tempSelectedPrice);
  };
  const handleProductAmount = (currency, value) => {
    setProductAmount(handleConvert(currency, value));
  };

  const suggestedPrices = fixPrice
    ? [
        {
          aud: 50,
          usd: 50,
          cad: 50,
          sgd: 50,
          gbp: 50,
          eur: 50,
          myr: 50,
          aed: 50,
          idr: 50,
        },
        {
          aud: 150,
          usd: 150,
          cad: 150,
          sgd: 150,
          gbp: 150,
          eur: 150,
          myr: 150,
          aed: 150,
          idr: 150,
        },
        {
          aud: 300,
          usd: 300,
          cad: 300,
          sgd: 300,
          gbp: 300,
          eur: 300,
          myr: 300,
          aed: 300,
          idr: 300,
        },
      ]
    : [
        {
          aud: 100,
          usd: 70,
          cad: 90,
          sgd: 90,
          gbp: 60,
          eur: 60,
          myr: 300,
          aed: 240,
          idr: 1002020,
        },
        {
          aud: 250,
          usd: 160,
          cad: 220,
          sgd: 220,
          gbp: 130,
          eur: 150,
          myr: 760,
          aed: 580,
          idr: 2505050,
        },
        {
          aud: 500,
          usd: 320,
          cad: 430,
          sgd: 440,
          gbp: 260,
          eur: 300,
          myr: 1510,
          aed: 1160,
          idr: 5010090,
        },
      ];
  const [selectedPrice, setSelectedPrice] = useState({
    value: suggestedPrices[1][currentCurrency ? currentCurrency.toLowerCase() : 'aud'],
    index: 1,
  });
  const suggestedRecurringModes = [
    { value: 'one-off', label: arabic ? 'فردي' : 'Single' },
    { value: 'week', label: arabic ? 'أسبوعي' : 'Weekly' },
    { value: 'month', label: arabic ? 'شهري' : 'Monthly' },
  ];
  const [selectedRecurring] = useState(mode ? suggestedRecurringModes[mode] : suggestedRecurringModes[0]);
  const handleAddNewProduct = () => {
    let tempProduct = selectedProduct;
    let customAmount = Number(productAmount) ? Number(productAmount) : Number(selectedPrice.value) ? Number(selectedPrice.value) : 100;
    tempProduct = Object.assign({}, tempProduct, {
      quantity: customAmount,
      recurring: selectedRecurring.value,
    });
    addProduct({product: tempProduct, currency: currentCurrency})
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    if (selectedCurrencyStore) {
      setCurrentCurrency(selectedCurrencyStore);
      selectedPrice ? handleSelectedItem(selectedCurrencyStore, selectedPrice.index) : handleProductAmount(selectedCurrencyStore, productAmount);
    } else {
      setCurrentCurrency('AUD');
      handleSelectedItem('AUD', selectedPrice.index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrencyStore]);
  useEffect(() => {
    products && !allProducts.length && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */
  return (
    <div
      dir={arabic ? 'rtl' : 'ltr'}
      className={`${arabic ? 'font-[AlmariMedium]' : ''} w-full rounded-lg py-2 md:py-4 flex flex-wrap items-center ${className}`}
    >
      <div className="basis-full grid grid-cols-12 gap-4">
        <div className="col-span-12 lg:mb-2 flex items-center justify-center" style={styles.shadow}>
          <ReactSelect
            options={productsList}
            className="max-h-[48px] w-full"
            defaultValue={selectedProduct}
            isSearchable={false}
            styles={styles}
            placeholder="Select Product"
            onChange={e => handleSelectedProduct(e)}
          />
        </div>
        <div className="col-span-12 flex flex-col md:flex-row gap-4 md:gap-10">
          <div className="flex-1 flex items-center w-full">
            <div className="flex items-center justify-center" style={styles.shadow}>
              <SelectCurrency onChangeCurrency={e => setCurrentCurrency(e.value)} classNamePrefix="orphan_select" hideFlag />
            </div>
            <div className="flex-1 flex justify-center items-center bg-white rounded-br-md rounded-tr-md h-12 px-2" style={styles.shadow}>
              <span className="text-sm border-r pr-2 text-[#78716C]">{currentCurrency}</span>
              <input
                value={productAmount}
                className="w-full p-2 rounded-lg text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent"
                type="number"
                onChange={e => {
                  setProductAmount(e.target.value);
                  setSelectedPrice(null);
                }}
                placeholder="Enter amount"
              />
            </div>
          </div>
          <div className="flex-1 flex gap-3 items-center">
            {suggestedPrices.map((item, index) => {
              return (
                <div key={`prices${index}`} className="flex-1">
                  <div
                    className={`h-12 text-lg md:text-xl transition-all duration-200 cursor-pointer flex justify-center items-center px-2 md:px-4 py-2 md:py-4 min-w-[80px] rounded-md ${
                      selectedPrice && selectedPrice.value === suggestedPrices[index][currentCurrency ? currentCurrency.toLowerCase() : 'aud']
                        ? 'bg-[#F60362] text-white'
                        : 'bg-white text-[#F60362] hover:bg-[#F60362] hover:text-white'
                    }`}
                    onClick={() => {
                      handleSelectedItem(currentCurrency, index);
                      setProductAmount('');
                    }}
                  >
                    {ShowCurrencies(currentCurrency, item[currentCurrency ? currentCurrency.toLowerCase() : 'aud'], true)}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* User Fields */}
        <div className="col-span-12 flex flex-col gap-4">
          <input
            value={props.giftingUser['username']}
            onChange={props.handleUserChange}
            name="username"
            placeholder="Enter their name"
            className="h-12 text-lg md:text-xl transition-all duration-200  flex justify-center items-center px-2 md:px-4 py-2 md:py-4 min-w-[80px] rounded-md outline-blue-500"
          />
          <input
            value={props.giftingUser['email']}
            onChange={props.handleUserChange}
            name="email"
            placeholder="Enter their email address"
            className="h-12 text-lg md:text-xl transition-all duration-200  flex justify-center items-center px-2 md:px-4 py-2 md:py-4 min-w-[80px] rounded-md outline-blue-500"
          />
          <textarea
            value={props.giftingUser['message']}
            onChange={props.handleUserChange}
            name="message"
            id=""
            cols="30"
            rows="4"
            placeholder="Send a personalised message"
            className="h-36 text-lg md:text-xl transition-all duration-200  flex justify-center items-center px-2 md:px-4 py-2 md:py-4 min-w-[80px] rounded-md outline-blue-500"
          ></textarea>
        </div>
        <div className="col-span-12 flex justify-center items-center">
          <button
            className={`text-white w-full text-sm md:text-lg transition-all duration-200 flex items-center justify-center h-12 px-3 rounded-md ${
              isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'
            }`}
            onClick={handleAddNewProduct}
          >
            {donateBtnText ? donateBtnText : 'Quick Donate'}
          </button>
        </div>
        <div className="col-span-12 flex justify-center items-center">
          <button
            className={`text-white w-full text-sm md:text-lg transition-all duration-200 flex items-center justify-center h-12 px-3 rounded-md bg-[#f60362]`}
            onClick={onShowModal}
          >
            Preview Certificate
          </button>
        </div>
      </div>
    </div>
  );
};

export default CrisisWidget;
